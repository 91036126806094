import React from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from "react-bootstrap";
import {Img} from "./Img";
import Button from "./Button";
import reactHtmlParser from "react-html-parser";
import {ImageParallaxSmall} from "./ImageParallaxSmall";


const BuildHome = ({offset, revers, padding, data}) => {


    return (
        <StyledBuilHome offset={offset} className={`BuilHome ${padding ? padding : 'pt-180 pb-180'}`}>
            <div  className="noise box"></div>
            <Container>
                <Row>
                    <Col md={{span: 6, offset: 6}}>
                        {
                            data?.section_data?.title &&
                            <h2 data-lag={'0.3'}>{data?.section_data?.title ? reactHtmlParser(data?.section_data?.title) : ''}</h2>
                        }

                        <div data-lag={'0.3'}>
                            {
                                data?.section_data?.description &&
                                reactHtmlParser(data?.section_data?.description)
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container fluid className={'padding-left'}>
                <Row>
                    <Col md={4}>

                        <div className="img-wrp-ratio box">
                            {
                                data?.images?.list?.[0]?.full_path &&
                                <Img
                                    src={data?.images?.list?.[0]?.full_path ? data?.images?.list?.[0]?.full_path : '/images/static/blurbg.svg'}/>
                            }
                        </div>


                        {
                            data?.section_data?.short_desc &&
                            window.innerWidth > 767 &&
                            <Button src={data?.section_data?.short_desc} margin={'80px 0 0'}
                                    text={'GET IN TOUCH TODAY'}/>

                        }


                    </Col>
                    <Col md={8}>

                        {
                            data?.images?.list?.[1]?.full_path &&
                            <div className="img-wrp-ratio-two">
                                <Img
                                    src={data?.images?.list?.[1]?.full_path ? data?.images?.list?.[1]?.full_path : '/images/static/blurbg.svg'}/>
                            </div>
                        }


                        {
                            data?.section_data?.short_desc &&
                            window.innerWidth < 767 &&
                            <Button src={data?.section_data?.short_desc} margin={'40px 0 0'}
                                    text={'GET IN TOUCH TODAY'}/>
                        }
                    </Col>
                </Row>
            </Container>
        </StyledBuilHome>
    )
};

const StyledBuilHome = styled.div`
  position: relative;
  background: #F1EEE9;

  .noise {
    position: absolute;
    inset: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-image: url('/images/static/rsz_1home-bg.jpg');
    background-repeat: no-repeat;
    background-position: right top;

    &:after {
      //animation: Noise_grain 5s infinite linear;
    }


  }


  .padding-left {
    padding-right: 0;
    padding-left: ${props => props.offset ? props.offset : '0'}px;
    margin-top: 60px;
  }

  .img-wrp-ratio {
    position: relative;
    padding-top: calc(362 / 370 * 100%);
  }

  .img-wrp-ratio-two {
    position: relative;
    padding-top: calc(654 / 868 * 100%);
  }

  h2 {
    margin-bottom: 85px;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: space-between;

    li {
      flex: 0 0 calc(50% - 15px);
    }
  }


  @media(min-width: 1024px){
    .img-wrp-ratio{
      top: 45px;
    }
  }
  @media (max-width: 767px) {
    .padding-left {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }

    .img-wrp-ratio {
      margin-bottom: 40px;
    }

    ul {
      flex-direction: column-reverse;

      li {
        flex: 0 0 100%;
      }
    }
  }
`;


export default React.memo(BuildHome);
