import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { TimelineLite } from 'gsap';
import disableScroll from 'disable-scroll';
import { useDispatch, useSelector } from 'react-redux';

const Message = ({ offset }) => {

  let tl = new TimelineLite();
  let tl2 = new TimelineLite();
  const socialMessage = useRef();
  const [isSocialListOpen, setIsSocialListOpen] = useState(false);

  // on click search input toggle action --  desktop menu
  useEffect(() => {
    // Attach the click event listener to the button (searchClickRef)
    socialMessage?.current.addEventListener('click', (e) => {
      disableScroll.off();

      if (isSocialListOpen === true) {

        setIsSocialListOpen(false);
      }

      if (isSocialListOpen === false) {
        // Open the social_list
        disableScroll.on();
        setIsSocialListOpen(true);

        // socialMessage.current.classList.add('social-open');
      }
    });
  }, [isSocialListOpen]);

  // disableScroll.on()

  return (
    <StyledMessage data-scroll-section offset={offset} className={`title `}
    >
      <div className={'message_wrapper'}>
        <ul className={`social_list ${isSocialListOpen === true ? 'social-open' : ''}`}>
          <li>
            <a href={'https://www.facebook.com/p/Formonic-Design-Construction-Ltd-100040422192332/"'} target={'_blank'}>
              <span>Faceboook</span>
              <div className={'hover'}>

                <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'>
                  <circle id='Ellipse_605' data-name='Ellipse 605' cx='20' cy='20' r='20'
                          fill='#C0845E' />
                  <circle id='Ellipse_602' data-name='Ellipse 601' cx='20' cy='20' r='0' fill='#0C286E' />

                  <path id='Path_8244' data-name='Path 8244'
                        d='M1205.858,104.045l.392-2.557H1203.8V99.83a1.278,1.278,0,0,1,1.441-1.381h1.116V96.272a13.6,13.6,0,0,0-1.98-.173,3.121,3.121,0,0,0-3.341,3.441v1.948h-2.246v2.557h2.246v6.18h2.764v-6.18Z'
                        transform='translate(-1182.787 -83.099)' fill='#f9f9f9' />
                </svg>
              </div>
            < /a>
          </li>
          <li>
            <a href={'https://www.linkedin.com/company/formonic-design-and-technology'}
               target={'_blank'}><span>Linkedin</span>
              <div className={'hover'}>

                <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'>
                  <circle id='Ellipse_605' data-name='Ellipse 605' cx='20' cy='20' r='20' fill='#C0845E' />
                  <circle id='Ellipse_602' data-name='Ellipse 601' cx='20' cy='20' r='0' fill='#0C286E' />
                  <g id='Group_17722' data-name='Group 17722' transform='translate(12 10)'>
                    <path id='Path_1569' data-name='Path 1569'
                          d='M18.3,18.322V12.289c0-2.965-.638-5.229-4.1-5.229a3.575,3.575,0,0,0-3.232,1.771h-.041v-1.5H7.66V18.322h3.418V12.866c0-1.441.268-2.821,2.038-2.821,1.75,0,1.771,1.626,1.771,2.9V18.3H18.3Z'
                          transform='translate(-1.833 -1.851)' fill='#f9f9f9'></path>
                    <path id='Path_1570' data-name='Path 1570' d='M2.26,7.32H5.678V18.314H2.26Z'
                          transform='translate(-1.992 -1.844)' fill='#f9f9f9'></path>
                    <path id='Path_1571' data-name='Path 1571'
                          d='M3.976,2A1.987,1.987,0,1,0,5.953,3.976,1.977,1.977,0,0,0,3.976,2Z'
                          transform='translate(-2 -2)' fill='#f9f9f9'></path>
                  </g>
                </svg>


              </div>
            </a>
          </li>

          <li>
            <a href={'https://www.instagram.com'} target={'_blank'}><span>Instagram</span>

              <div className={'hover'}>

                <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'>
                  <circle id='Ellipse_604' data-name='Ellipse 604' cx='20' cy='20' r='20' fill='#C0845E' />
                  <circle id='Ellipse_602' data-name='Ellipse 601' cx='20' cy='20' r='0' fill='#0C286E' />

                  <g id='Group_16352' data-name='Group 16352' transform='translate(12.791 12.792)'>
                    <g id='Group_16351' data-name='Group 16351' transform='translate(0 0)'>
                      <path id='Path_8240' data-name='Path 8240'
                            d='M1095.761,105.945a.845.845,0,1,0,.845.845A.845.845,0,0,0,1095.761,105.945Z'
                            transform='translate(-1084.736 -103.371)' fill='#f9f9f9' />
                      <path id='Path_8241' data-name='Path 8241'
                            d='M1082.605,108.605a3.55,3.55,0,1,0,3.551,3.551A3.555,3.555,0,0,0,1082.605,108.605Zm0,5.825a2.274,2.274,0,1,1,2.274-2.274A2.276,2.276,0,0,1,1082.605,114.43Z'
                            transform='translate(-1075.337 -104.947)' fill='#f9f9f9' />
                      <path id='Path_8242' data-name='Path 8242'
                            d='M1080.018,114.045h-5.756a4.336,4.336,0,0,1-4.331-4.331v-5.756a4.335,4.335,0,0,1,4.331-4.33h5.756a4.335,4.335,0,0,1,4.33,4.33v5.756A4.336,4.336,0,0,1,1080.018,114.045Zm-5.756-13.06a2.977,2.977,0,0,0-2.974,2.974v5.756a2.977,2.977,0,0,0,2.974,2.974h5.756a2.978,2.978,0,0,0,2.974-2.974v-5.756a2.977,2.977,0,0,0-2.974-2.974Z'
                            transform='translate(-1069.932 -99.628)' fill='#f9f9f9' />
                    </g>
                  </g>
                </svg>


              </div>
            </a>

          </li>

          <li>
            <a href={'https://www.youtube.com/'} target={'_blank'}><span>Youtube</span>
              <div className='hover'>


                <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'>
                  <circle id='Ellipse_603' data-name='Ellipse 603' cx='20' cy='20' r='20' fill='#C0845E' />
                  <circle id='Ellipse_602' data-name='Ellipse 601' cx='20' cy='20' r='0' fill='#0C286E' />

                  <path id='Path_8239' data-name='Path 8239'
                        d='M1151.218,114.079a3.762,3.762,0,0,0-3.762-3.762h-9.619a3.762,3.762,0,0,0-3.762,3.762v4.477a3.762,3.762,0,0,0,3.762,3.762h9.619a3.762,3.762,0,0,0,3.762-3.762Zm-5.657,2.574-4.314,2.134c-.169.092-.743-.031-.743-.223v-4.381c0-.195.579-.317.748-.22l4.13,2.246C1145.555,116.307,1145.736,116.558,1145.561,116.653Z'
                        transform='translate(-1122.646 -96.317)' fill='#f9f9f9' />
                </svg>


              </div>
            </a>
          </li>
          <li>
            <a href={`https://wa.me/+8801708080822`} target={'_blank'}><span>Whats app</span>
              <div className='hover'>

                <svg enable-background='new 0 0 128 128' height='128px' id='Layer_1' version='1.1' viewBox='0 0 128 128'
                     width='128px'  xmlns='http://www.w3.org/2000/svg'
                     ><circle cx='64' cy='64' fill='#C0845E' r='64'/>
                  <g><path d='M92.346,35.49c-7.522-7.53-17.523-11.678-28.179-11.683c-21.954,0-39.826,17.868-39.833,39.831   c-0.004,7.022,1.831,13.875,5.316,19.913L24,104.193l21.115-5.538c5.819,3.171,12.369,4.844,19.036,4.847h0.017l0,0   c21.954,0,39.823-17.871,39.832-39.833C104.005,53.027,99.864,43.019,92.346,35.49 M64.168,96.774h-0.013   c-5.943-0.002-11.769-1.598-16.853-4.614l-1.209-0.718l-12.53,3.287l3.343-12.216l-0.787-1.256   c-3.315-5.27-5.066-11.361-5.062-17.619c0.006-18.253,14.859-33.104,33.121-33.104c8.844,0.002,17.155,3.451,23.407,9.71   c6.251,6.258,9.691,14.575,9.689,23.422C97.268,81.922,82.415,96.774,64.168,96.774 M82.328,71.979   c-0.996-0.499-5.889-2.904-6.802-3.239c-0.913-0.332-1.574-0.497-2.238,0.499s-2.571,3.239-3.153,3.903   c-0.58,0.664-1.16,0.748-2.156,0.249s-4.202-1.549-8.001-4.941c-2.96-2.637-4.958-5.899-5.538-6.895s-0.062-1.533,0.437-2.03   c0.448-0.446,0.996-1.162,1.493-1.744c0.497-0.582,0.663-0.997,0.995-1.66c0.332-0.664,0.167-1.245-0.083-1.743   c-0.25-0.499-2.24-5.398-3.068-7.391c-0.809-1.941-1.629-1.678-2.239-1.708c-0.582-0.028-1.245-0.036-1.908-0.036   c-0.663,0-1.742,0.249-2.654,1.246c-0.911,0.996-3.483,3.403-3.483,8.304c0,4.898,3.566,9.632,4.064,10.295   c0.498,0.663,7.018,10.718,17.002,15.029c2.374,1.024,4.229,1.637,5.674,2.097c2.384,0.759,4.554,0.65,6.27,0.394   c1.912-0.285,5.888-2.407,6.719-4.732c0.829-2.324,0.829-4.316,0.578-4.732C83.986,72.727,83.322,72.478,82.328,71.979' fill='#FFFFFF'/></g></svg>


              </div>
            </a>
          </li>
        </ul>
        <div id={'click_message'} ref={socialMessage}
             className={`hover social_bar ${isSocialListOpen === true ? 'social-open' : ''}`}>
          <div className='overlay_message'></div>


          <svg className='hover_bar' width='40' height='40' viewBox='0 0 40 40'>
            <defs>
              <filter id='Ellipse_464' x='0' y='0' width='40' height='40' filterUnits='userSpaceOnUse'>
                <feOffset dy='5' input='SourceAlpha'></feOffset>
                <feGaussianBlur stdDeviation='5' result='blur'></feGaussianBlur>
                <feFlood flood-opacity='0.102'></feFlood>
                <feComposite operator='in' in2='blur'></feComposite>
                <feComposite in='SourceGraphic'></feComposite>
              </filter>
            </defs>
            <g transform='matrix(1, 0, 0, 1, 0, 0)' filter='url(#Ellipse_464)'>
              <circle id='Ellipse_464-2' data-name='Ellipse 464' cx='5' cy='10' r='20'
                      transform='translate(15 10)' fill='#C0845E'></circle>
              <circle id='Ellipse_464-3' data-name='Ellipse 464' cx='5' cy='10' r='0'
                      transform='translate(15 10)' fill='#0C286E'></circle>
            </g>
            <path id='Path_8262' data-name='Path 8262'
                  d='M14.113,11.136c-.967-.827-1.947-1.327-2.9-.5l-.57.5c-.417.362-1.193,2.054-4.191-1.395s-.74-3.13-.8-4.34.573-.5.573-.5c.95-.827.591-1.869-.094-2.941L5.72,1.308C5.032.238,4.283-.464,3.331.362l-.515.45a4.83,4.83,0,0,0-1.883,3.2c-.343,2.272.74,4.873,3.222,7.727s4.906,4.29,7.2,4.265a4.843,4.843,0,0,0,3.425-1.419l.516-.45c.95-.825.36-1.666-.608-2.494Z'
                  transform='translate(11.672 12)' fill='#f9f9f9'></path>
          </svg>


          <svg className={'close'} xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'>
            <circle id='Ellipse_601' data-name='Ellipse 601' cx='20' cy='20' r='20' fill='#C0845E'></circle>
            <circle id='Ellipse_602' data-name='Ellipse 601' cx='20' cy='20' r='0' fill='#0C286E'></circle>
            <g id='Group_5733' data-name='Group 5733' transform='translate(-1223.293 -15.293)'>
              <line id='Line_29' data-name='Line 29' y1='10' x2='10' transform='translate(1238 30)'
                    fill='none' stroke='#ffffff' stroke-linecap='round' stroke-width='1' />
              <line id='Line_30' data-name='Line 30' x2='10' y2='10' transform='translate(1238 30)'
                    fill='none' stroke='#ffffff' stroke-linecap='round' stroke-width='1' />
            </g>
          </svg>
        </div>

      </div>
    </StyledMessage>

  );
};


const StyledMessage = styled.div`
  position: fixed;
  right: 60px;
  bottom: 60px;
  z-index: 990;

  .overlay_message {
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    position: fixed;
    touch-action: none;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
    display: none;
  }

  .message_wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    li {
      a {
        position: relative;
        display: flex;
        text-align: right;
        align-items: center;
        justify-content: flex-end;

        span {
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
          color: #F9F9F9;
          margin-right: 15px;
        }
      }
    }

    .hover {
      height: 40px;
      width: 40px;
      overflow: hidden;
      border-radius: 50% !important;
      //background: #C0845E;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
      //border: 1px solid #ffffff;
      transition: 0.7s all cubic-bezier(0.4, 0, 0, 1);
      cursor: pointer;
      position: relative;

      svg {
        path, line, #Ellipse_464-3 {
          transition: 0.7s all cubic-bezier(0.4, 0, 0, 1);
        }
      }

      a {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 3;
      }

      &:hover {
        //border: 1px solid #1E5C95;
        //background: #C0845E;

        svg {
          position: relative;
          z-index: 1;

          path {
            fill: white;

          }

          #Ellipse_464-3 {
            r: 20;
          }

          line {
            stroke: white;
          }
        }

        &:after {
          border: none;

        }
      }

      &:after {
        display: none;
      }

      .hover_bar {
        visibility: visible;
        opacity: 1;
        transition: 0.5s all cubic-bezier(0.4, 0, 0, 1);
        display: block;
      }

      .close {
        visibility: hidden;
        opacity: 0;
        transition: 0.5s all cubic-bezier(0.4, 0, 0, 1);
        display: none;

        #Ellipse_602 {
          transition: 0.5s all cubic-bezier(0.4, 0, 0, 1);
        }
      }

      &.social-open {
        .overlay_message {
          display: block;
        }

        .close {
          visibility: visible;
          opacity: 1;
          transition: 0.7s all cubic-bezier(0.4, 0, 0, 1);
          display: block;

          &:hover {
            #Ellipse_602 {
              r: 20;
            }
          }
        }

        .hover_bar {
          visibility: hidden;
          opacity: 0;
          transition: 0.7s all cubic-bezier(0.4, 0, 0, 1);
          display: none;

        }

      }
    }

    svg {

    }
  }

  .social_list {
    position: relative;
    height: 0;
    z-index: 0;
    overflow: hidden;
    bottom: 0;
    transition: 0.7s all ease;
    visibility: hidden;

    &.social-open {
      height: auto;
      opacity: 1;
      visibility: visible;
      transition: 0.7s all ease;

    }

    li {
      a {
        svg {
          height: 40px;
          width: 40px;
          #Ellipse_602 {
            transition: 0.5s all cubic-bezier(0.4, 0, 0, 1);
          }
        }

        &:hover {
          #Ellipse_602 {
            r: 20;
          }
        }
      }
    }
  }

  ul {
    li {
      margin-bottom: 10px;
    }
  }

  @media (max-width: 767px) {
    right: 15px;
    left: unset;
    bottom: 40px;
  }

  .hover {
    &:after {
      display: none !important;
    }
  }
`;


export default Message;














