import React,{useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import TeamBox from "./TeamBox";
import reactHtmlParser from "react-html-parser";
import Button from './Button';
import MDPopup from './MDPopup';

const ManagingDirectors = (data) => {

    const [show, setShow] = useState(false);
    const [popupId, setPopupId] = useState()

    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        setShow(true)
        setPopupId(e)
    }
    return (
        <StyledComponent className={'md-message pt-120 '}>
            <MDPopup show={show} data={data} handleClose={handleClose}/>

            <Container>
                <Row>
                    <Col md={4}>
                        {
                            window?.innerWidth < 767 &&
                            <h2 className={'split-up mobile-title'}>
                                {data?.data?.section_data?.title ? reactHtmlParser(data?.data?.section_data?.title) : 'MD\'S MESSAGE'}
                            </h2>

                        }
                        <div className="box-up">
                            <TeamBox  md title={data?.data?.section_data?.subtitle}
                                     des={data?.data?.section_data?.short_desc}
                                     img={data?.data?.images?.list?.[0]?.full_path ? data?.data?.images?.list?.[0]?.full_path : 'images/static/blurbg.svg'}/>
                        </div>
                    </Col>
                    <Col md={{span: 7, offset: 1}}>
                        <div className="contents">
                            {
                                window?.innerWidth > 767 &&
                                <h2 className={'split-up'}>{data?.data?.section_data?.title ? reactHtmlParser(data?.data?.section_data?.title) : ''}</h2>

                            }
                            {
                                data?.data?.section_data?.description ?
                                reactHtmlParser(data?.data?.section_data?.description.slice(0, 700)+'...') : ''
                            }
                            <div className="button" onClick={() => handleShow()}>
                                <Button src={'#'} margin={'40px 0 0'}
                                        color={'#26201E'} icon_border={'#26201E'} text={'LEARN MORE'}/>
                            </div>
                        </div>

                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background: #f1eee9;

  .contents {
    h2 {
      margin-bottom: 90px;
    }

    p {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 767px) {
    .mobile-title {
      margin-bottom: 45px;
    }

    .team-box {
      margin-bottom: 60px;
    }
  }
`;

export default React.memo(ManagingDirectors);
