import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    globalLoader: true,
}

const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        loading: (state) => {
            state.globalLoader = true
        }
    },
    extraReducers: {
        ['fetchDataHome/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchData/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchAbout/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchDataLeader/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchCareer/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchProjects/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchServices/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchConsultancy/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchConcern/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchContacts/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchDataDetailAll/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
    }
})


export default globalSlice.reducer
export const {loaded, loading} = globalSlice.actions
