import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {useEffect, useRef, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Mousewheel, Navigation, Pagination} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {Link} from "react-router-dom";
import {Img} from "./Img";
import {hover} from "../styles/globalStyleVars";
import Title from "./Title";
import NavigationIcon from "./NavigationIcon";

const RandomSliderV1 = ({Data, data}) => {


    // SwiperCore.use([Autoplay]);
    let containerRef = useRef();
    let sliderRef = useRef();

    let [offset, setOffset] = useState(90)
    let [theWidth, SetTheWidth] = useState(0)
    let [activeNumber, setActiveNumber] = useState(1)
    let [totalNumber, setTotalNumber] = useState(1)


    useEffect(() => {
        setOffset(containerRef.current?.offsetLeft)
        window.addEventListener('resize', () => {
            setOffset(containerRef.current?.offsetLeft)
            SetTheWidth(window.innerWidth)
        })

        // slider number
        const getActiveItem = document.querySelector('.swiper-pagination-current')?.innerHTML
        const getTotalItem = document.querySelector('.swiper-pagination-total')?.innerHTML
        setActiveNumber(getActiveItem)
        setTotalNumber(getTotalItem)
    }, [Data])


    useEffect(() => {
        if (sliderRef.current) {
            const swiperInstance = sliderRef.current.swiper;
            if (swiperInstance) {
                setTotalNumber(swiperInstance.slides.length);
            }
        }
    }, [Data])


    const [swiperIndex, setSwiperIndex] = useState(0);


    const handleSlideChange = (swiper) => {
        setSwiperIndex(swiper.realIndex);
    };




    return (
        <StyledBlog offset={offset} className='blog-slider pt-180 pb-180'>
            <Container ref={containerRef}>
                <Row>
                    {
                        window.innerWidth > 767 &&
                        <Col md={6}>
                            <NavigationIcon next_id={'act-next'} prev_id={'act-prev'}/>
                        </Col>
                    }
                    <Col sm={6}>
                        <div className="blog-title">
                            <Title margin={'0 0 50px 0'} text={'OUR ACTIVITIES'}/>

                        </div>

                    </Col>
                </Row>
            </Container>

            <div className={` fade-up blog-slider__slider-wrap`}>
                <div className="blog-slider__slider-wrap__inner">
                    {
                        data?.posts?.list &&
                        <Swiper
                            loop={true}
                            autoplay={true}
                            slidesPerView={2}
                            spaceBetween={30}
                            speed='1500'
                            slidesPerGroup={1}
                            keyboardControl={true}
                            modules={[Autoplay, Pagination, Navigation, Mousewheel]}
                            navigation={{
                                prevEl: '#act-prev',
                                nextEl: '#act-next',
                            }}
                            onSlideChange={handleSlideChange}
                            breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                    spaceBetween: 30,

                                },
                                768: {
                                    slidesPerView: 1,
                                    spaceBetween: 30,

                                },
                                1024: {
                                    slidesPerView: 2,
                                    spaceBetween: 30,
                                },
                            }}
                            ref={sliderRef}
                            className="mySwiper"
                        >
                            {
                                data?.posts?.list?.length>0 &&
                                data?.posts?.list?.map((e , i)=>{
                                    return(
                                        <SwiperSlide key={i}>
                                            <div className='blog-single fade-up'>
                                                <div className="blog-single__inner">
                                                    <Link to={'/blog-details'}></Link>
                                                    <Img src={e?.images[0]?.full_path} objectFit={'cover'} layout={'fill'}/>
                                                    <div className="blog-single__inner__content">
                                                        <div className="blog-single__inner__content__top">
                                                            <p>{e?.data?.description}</p>
                                                            <h4>{e?.data?.title}</h4>

                                                        </div>
                                                        <div className="blog-single__inner__content__bottom">
                                                            <h4>{e?.data?.subtitle}</h4>
                                                            <p>{e?.data?.short_desc}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    );
                                })
                            }
                        </Swiper>
                    }

                </div>


                {
                    window.innerWidth < 767 &&
                    <NavigationIcon next_id={'act-next'} prev_id={'act-prev'}/>

                }
            </div>
        </StyledBlog>
    );
};

const StyledBlog = styled.section`
    background: #fff;
  .blog-title {
    position: relative;

    p {
      position: absolute;
      top: 0;
      right: 0;

    }

  }

  .blog-button {
    display: flex;
    justify-content: space-between;
    margin-bottom: 85px;
    align-items: center;

    .slider-nav {
      ul {
        display: flex;
      }

      li {
        height: 50px;
        width: 50px;
        background-color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;

        &:nth-of-type(1) {
          margin-right: 20px;
        }

        svg {
          color: #ffffff;
          z-index: 2;
        }
      }
    }
  }

  .swiper-button-next, .swiper-button-prev {
    position: absolute;
    height: 40px;
    width: 40px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 99;
    background-color: red;
  }

  .swiper-button-next, .swiper-button-prev, .swiper-pagination {
    opacity: 0;
    visibility: hidden;
  }

  .swiper-initialized {
    padding-left: ${props => props.offset + 200}px;
    padding-right: ${props => props.offset + 200}px;
  }

  .slider-nav-mobile {
    display: none;
  }

  .global-image{
    &:after{
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: #000;
      opacity: 0.3;
      height: 100%;
      width: 100%;
    }
  }
  
  .blog-slider {
    &__slider-wrap {
      &__inner {
        .blog-single {
          &__inner {
            padding-top: calc(520 / 370 * 100%);
          }
        }
      }
    }
  }

  .blog-single__inner {
    padding-top: calc(520 / 370 * 100%);
    position: relative;

    a {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      z-index: 3;
    }

    &__content {
      &:after {
        opacity: 1;
        content: '';
        height: 0;
        bottom: 0;
        width: 100%;
        background-color: #26201E;
        left: 0;
        right: 0;
        position: absolute;
        transition: all .4s ease;
      }

      &__top {
        p {
          font-size: 16px;
          font-weight: 500;
          line-height: 22px;
          color: #ffffff;
          position: absolute;
          left: 40px;
          top: 40px;
          z-index: 2;
          right: 40px;
          margin: 0;
          transform: translateY(-30px);
          opacity: 0;
          transition: all .6s ease;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
        }

        h4 {
          font-size: 24px;
          font-weight: 500;
          position: absolute;
          top: 40px;
          left: 40px;
          right: 40px;
          z-index: 2;
          color: #F1EEE9;
          margin: 0;
          transition: all .3s ease;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
        }
      }

      &__bottom {
        position: absolute;
        margin: 0;
        left: 40px;
        right: 40px;
        bottom: 24px;
        display: flex;
        justify-content: space-between;
        //border-top: 1px solid #221F1F;
        padding-top: 20px;
        z-index: 2;
        align-items: baseline;
    
        h4 {
          font-size: 80px;
          font-weight: 300;
          color: #F1EEE9;
          line-height: 65px;
          transition: color .3s ease;
        }

        p {
          color: #F1EEE9;
          text-align: right;
          transition: color .3s ease;

          span {
            display: block;
            color: ${hover};
          }
        }
      }
    }

    &:hover {
      .blog-single__inner__content:after {
        height: 100%;
      }

      .blog-single__inner__content__top {
        h4 {
          opacity: 0;
          transform: translateY(-20px);
        }

        p {
          transform: none;
          opacity: 1;
        }
      }

      .blog-single__inner__content__bottom {
        border-color: #FFF;

        h4 {
          color: #ffffff;
        }

        p {
          color: #ffffff;

          span {
            color: #ffffff;
          }
        }
      }
    }

  }


  @media (max-width: 767px) {
    
    .navigation_button{
      margin-top: 40px;
    }
    .blog-single__inner__content__top {
      p, h2 {
        left: 30px;
        right: 30px;
        top: 30px;
      }
    }

    .blog-single__inner__content__bottom h4, .blog-single__inner__content__bottom p {
      left: 30px;
      right: 30px;
      top: 30px;
    }

    .swiper-initialized {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .blog-slider {
      &__slider-wrap {
        margin-left: 15px;
        margin-right: 15px;

        .slider-nav-mobile {
          margin-top: 40px;

          ul {
            display: flex;
          }

          li {
            height: 50px;
            width: 50px;
            background-color: #000;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            cursor: pointer;

            &:nth-of-type(1) {
              margin-right: 20px;
            }

            svg {
              color: #ffffff;
              z-index: 2;
            }
          }
        }
      }
    }

    .blog-button {
      margin-bottom: 40px;

      .slider-nav {
        display: none;
      }
    }

    .slider-nav-mobile {
      display: block;
    }

  }

`;
export default RandomSliderV1;