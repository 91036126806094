import React from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from "react-bootstrap";
import Title from "../Title";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";

const OverviewInner = ({data}) => {


    return (
        <StyledOverviewInner className={`about_overview pt-180 pb-180`}>
            <div className="noise"></div>
            <Container>
                <Row>
                    <Col md={{span: 7}}>
                        <Title margin={'0 0 90px'} text={reactHtmlParser(data?.section_data?.title)}/>
                    </Col>
                </Row>
                <Row>
                    <Col md={5}>
                        <div className="img_wrapper reveal box-down">
                            <Img src={data?.images?.list[0]?.full_path}/>
                        </div>
                    </Col>
                    <Col md={{span: 6, offset: 1}}>
                        <div className="contents">
                            {reactHtmlParser(data?.section_data?.description)}
                            <div className="short-description">
                                <h4 className={'split-up'}>
                                    {
                                        reactHtmlParser(data?.section_data?.subtitle)
                                    }
                                </h4>
                                <p className={'split-up'}>
                                    {
                                        reactHtmlParser(data?.section_data?.short_desc)
                                    }
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledOverviewInner>
    )
};

const StyledOverviewInner = styled.div`
  position: relative;
  overflow: hidden;

  // overview_bg
  .noise {
    position: absolute;
    inset: 0;
    overflow: hidden;
    width: calc(100% + 500px);
    height: calc(100% + 500px);

    &:after {
      content: '';
      background: #2F374B;
      background-image: url('/images/static/background-noise.png');
      //background-repeat: repeat;
      background-repeat: repeat;
      background-blend-mode: multiply;
      //animation: Noise_grain 5s infinite linear;
      //width: calc(100% + 500px);
      //height: calc(100% + 500px);
      width: 100%;
      height: 100%;
      position: absolute;
      inset: -30px 0 0 0;
    }

    &:after {
      //animation: Noise_grain 5s infinite linear;
    }


  }

  h4 {
    color: #C0845E;
  }

  h2, p {
    color: #F1EEE9;
  }

  .img_wrapper {
    padding-top: calc(680 / 470 * 100%);
    position: relative;
  }

  .contents {
    p {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .short-description {
      margin-top: 46px;
      width: 70%;

      h4 {
        margin-bottom: 40px;
        font-size: 15px;
        font-weight: 300;
        line-height: 22px;
        letter-spacing: 0.7px;
      }

    }

  }

  @media (max-width: 767px) {
    .title {
      margin-bottom: 60px;
    }

    .img_wrapper {
      margin-bottom: 40px;
    }

    .contents {
      margin-top: 40px;
      .short-description {
        width: 100%;
      }

    }
  }

`;


export default React.memo(OverviewInner);
