import React, { useContext } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { TransitionProvider } from '../context/TransitionContext';
import TransitionComponent from '../components/Transition';
import About from '../pages/about';
import Home from '../pages/home';
import Project from '../pages/project';
import ForSale from '../pages/forsale';
import ProjectDetail from '../pages/project/single';
import News from '../pages/news';
import NewsDetail from '../pages/news/single';
import Career from '../pages/career';
import CSR from '../pages/csr';
import Landowner from '../pages/landowner';
import Buyer from '../pages/buyer';
import ContactUs from '../pages/contacts';
import Services from '../pages/services';
import ConcernDetails from '../pages/concern/single';
import ServicesDetails from '../pages/services/single';
import Consultancy from '../pages/consultancy';
import Concern from '../pages/concern';
import Error from '../pages/404';

const Router = () => {
  const location = useLocation();
  return (

      <TransitionProvider>
        <Routes>
          <Route exact
                 index
                 element={
                   <TransitionComponent>
                     <Home />
                   </TransitionComponent>
                 }
          />
          <Route exact
                 path='/about'
                 element={
                   <TransitionComponent>
                     <About />
                   </TransitionComponent>
                 }
          />
          <Route exact
                 path='/csr'
                 element={
                   <TransitionComponent>
                     <CSR />
                   </TransitionComponent>
                 }
          />
          <Route exact
                 path='/project'
                 element={
                   <TransitionComponent>
                     <Project />
                   </TransitionComponent>
                 }
          />
          <Route exact
                 path='/project/:slug'
                 element={
                   <TransitionComponent>
                     <ProjectDetail />
                   </TransitionComponent>
                 }
          />
          <Route exact
                 path='/concerns/:slug'
                 element={
                   <TransitionComponent>
                     <ConcernDetails />
                   </TransitionComponent>
                 }
          />
          <Route exact
                 path='/services/:slug'
                 element={
                   <TransitionComponent>
                     <ServicesDetails />
                   </TransitionComponent>
                 }
          />
          <Route exact
                 path='/news'
                 element={
                   <TransitionComponent>
                     <News />
                   </TransitionComponent>
                 }
          />
          <Route exact
                 path='/news/:slug'
                 element={
                   <TransitionComponent>
                     <NewsDetail />
                   </TransitionComponent>
                 }
          />
          <Route exact
                 path='/career'
                 element={
                   <TransitionComponent>
                     <Career />
                   </TransitionComponent>
                 }
          />
          <Route exact
                 path='/services'
                 element={
                   <TransitionComponent>
                     <Services />
                   </TransitionComponent>
                 }
          />
          <Route
            path='/consultancy'
            element={
              <TransitionComponent>
                <Consultancy />
              </TransitionComponent>
            }
          />
          <Route exact
                 path='/landowner'
                 element={
                   <TransitionComponent>
                     <Landowner />
                   </TransitionComponent>
                 }
          />
          <Route exact
                 path='/for-sale'
                 element={
                   <TransitionComponent>
                     <ForSale />
                   </TransitionComponent>
                 }
          />
          <Route exact
                 path='/buyer'
                 element={
                   <TransitionComponent>
                     <Buyer />
                   </TransitionComponent>
                 }
          />
          <Route exact
                 path='/concerns'
                 element={
                   <TransitionComponent>
                     <Concern />
                   </TransitionComponent>
                 }
          />
          <Route exact
                 path='/contact-us'
                 element={
                   <TransitionComponent>
                     <ContactUs />
                   </TransitionComponent>
                 }
          />
          {/* Other routes */}
          <Route path="*" element={<Error />} />
        </Routes>
      </TransitionProvider>

  );
};

export default Router;
