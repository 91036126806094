import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from "react-bootstrap";
import Title from "../Title";
import Button from "../Button";
import reactHtmlParser from "react-html-parser";

const OverviewInner = ({data}) => {
    return (
        <StyledOverviewInner className={`about_overview pt-180 pb-180`}>
            <div className="noise"></div>
            <div className="icon-two boxr">
                <img src="/images/static/custom_3d_shapes_white_1.svg" alt=""/>
            </div>
            <div className="icon-one box">
                <img src="/images/static/custom_3d_shapes_cube_1.svg" alt=""/>
            </div>

            <Container>
                <Row>
                    <Col md={{span: 6}}>
                        <div className="contents" data-lag={0.3}>
                            <h4>
                                {reactHtmlParser(data?.data?.subtitle)}
                            </h4>
                        </div>
                    </Col>
                    <Col md={{span: 6}}>
                        <div className="contents">
                            {reactHtmlParser(data?.data?.description)}
                            <Button margin={'40px 0 0'} src={'/'} color={'#F1EEE9'} icon_border={'#F1EEE9'}
                                    text={'Visit website'}/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledOverviewInner>
    )
};

const StyledOverviewInner = styled.div`
  position: relative;
  overflow: hidden;
  .icon-one{
    position: absolute;
    z-index: 1;
    right: -90px;
    top: 30px;
    filter: blur(5px);
  }
  .icon-two {
    position: absolute;
    z-index: 1;
    left: -100px;
    top: 60%;
    filter: blur(5px);
  }

  .dc-btn{
    width: auto !important;
    a{
      justify-content: flex-start;
    }
  }
  h4, p {
    color: #F1EEE9;
  }

  // overview_bg
  .noise {
    position: absolute;
    inset: 0;
    overflow: hidden;
    width: calc(100% + 500px);
    height: calc(100% + 500px);
    background: #C0845E;

    &:after {
      content: '';
      background-image: url('/images/static/background-noise.png');
      background-blend-mode: multiply;
      //animation: Noise_grain 5s infinite linear;
      //width: calc(100% + 500px);
      //height: calc(100% + 500px);
      width: 100%;
      height: 100%;
      position: absolute;
      inset: -30px 0 0 0;
      opacity: 0.3;
    }

    &:after {
      //animation: Noise_grain 5s infinite linear;
    }


  }

  @media (max-width: 767px) {
    .title {
      margin-bottom: 60px;
    }

    .contents {
      h4 {
        margin-bottom: 40px;

        br {
          display: none;
        }
      }
    }


  }

`;


export default React.memo(OverviewInner);
