import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import { apiEndPoints } from '../../api/network/apiEndPoints';
import { fetchPosts, fetchPostsALL, fetchPostsFilter } from '../../api/redux/projects';
import { useDispatch, useSelector } from 'react-redux';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import InnerBanner from '../../components/InnerBanner';
import { ApiParam } from '../../api/network/apiParams';
import ProjectBox from '../../components/ProjectBox';
import SelectField from '../../components/SelectField';
import gsap, { CSSPlugin, TimelineLite } from 'gsap';
import { SplitText } from 'gsap/SplitText';
import { useLocation } from 'react-router-dom';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(SplitText, CSSPlugin, ScrollTrigger);

const MyComponent = () => {

  const dispath = useDispatch();
  const router = useLocation();

  // api call
  useEffect(() => {
    let api_url = apiEndPoints.SECTIONS;
    let all_product = apiEndPoints.ALL_PRODUCTS;
    let param = {
      [ApiParam.TYPE]: 'slug',
      [ApiParam.VALUE]: 'projects',
      [ApiParam.GET_SECTION]: 'yes',
      [ApiParam.IMAGE]: 'yes',
      [ApiParam.POST]: 'yes',
      [ApiParam.GALLERY]: 'yes',
    };

    let api_url_filter = apiEndPoints.FILTER_PROJECT;

    let param_cat = {
      [ApiParam.CATEGORY_ID]: '',
      [ApiParam.TYPE]: '',
      [ApiParam.LOCATION]: '',
      [ApiParam.IMAGE]: 'yes',
      [ApiParam.POST]: 'yes',
      [ApiParam.GALLERY]: 'yes',
      [ApiParam.FILE]: 'yes',
      [ApiParam.SPECIFICATION]: 'yes',

    };
    dispath(fetchPosts([api_url, param]));
    dispath(fetchPostsFilter([api_url_filter, param_cat]));
    dispath(fetchPostsALL([all_product, param]));
  }, [dispath,router.pathname]);


  let getPost = useSelector(state => state.projectsReducer);
  let page_data = getPost?.posts?.data?.page_data;
  let all_product = getPost?.all?.data;

  let cats = getPost?.detail?.filter;


  //Filter

  const type = cats?.type && cats?.type?.map(item1 => ({
    value: item1?.title,
    label: item1?.title,
  }));


  const category = cats?.category && cats?.category?.map(item1 => ({
    value: item1?.title,
    label: item1?.title,
  }));


  const location = cats?.location_list && cats?.location_list?.map(item1 => ({
    value: item1?.location,
    label: item1?.location,
  }));


  // Define state variables for selected filters
  const [selectedType, setSelectedType] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedLocation, setSelectedLocation] = useState();
  const [filteredData, setFilteredData] = useState(all_product);

  useEffect(() => {
    // Filter the data based on selected filters
    const newFilteredData = all_product && all_product.filter(item => {
      const typeMatch = !selectedType || item.product_data.type_title === selectedType.value;
      const statusMatch = !selectedStatus || item.product_data.category_title === selectedStatus.value;
      const locationMatch = !selectedLocation || item.product_data.location === selectedLocation.value;

      return typeMatch && statusMatch && locationMatch;
    });

    setFilteredData(newFilteredData);

    ScrollTrigger.refresh();
  }, [selectedType, selectedStatus, selectedLocation, all_product]);

  // Callback function to handle selected type
  const handleTypeSelect = (selectedValue) => {
    setSelectedType(selectedValue);
  };

// Callback function to handle selected status
  const handleStatusSelect = (selectedValue) => {
    setSelectedStatus(selectedValue);
    console.log(selectedValue)
  };

// Callback function to handle selected location
  const handleLocationSelect = (selectedValue) => {
    setSelectedLocation(selectedValue);
  };


  // Use useLocation to get the current location
  const queryParams = new URLSearchParams(router.search);
  const status = queryParams.get('status');

  useEffect(() => {

    if (status) {
      const statusOptions = [
        { value: 'Ongoing', label: 'Ongoing' },
        { value: 'Completed', label: 'Completed' },
        { value: 'Upcoming', label: 'Upcoming' },
      ];
      const statusOption = statusOptions.find((option) => option.value === status);
      handleStatusSelect(statusOption);

      // Step 1: Select the element by its class name
      var element = document.querySelector('.status_field .filter__placeholder');

      // Step 2: Update the innerHTML property
      element.innerHTML = status;
    }

  }, [status, router.pathname]); // Include 'status' in the dependency array to react to changes


  // for close menu
  let tl2 = new TimelineLite();
  useEffect(() => {
    document?.querySelector('.main-menu')?.classList.remove('menu-is-open');
    document.body.classList.remove('stop-scroll');
    let getItemsParent = document.querySelector('.main-menu-mobile__items');

    if (document.querySelector('.main-item.active')) {
      getItemsParent.classList.remove('active');
      document.querySelector('.main-item.active').classList.remove('active');
    }

    tl2.to('.main-menu__items__list', {
      duration: .2,
      y: '-40px',
      alpha: 0,
    }).to('.main-menu__items', {
      opacity: '0',
      duration: '.3',
    }).to('.main-menu__items__img', {
      opacity: '0',
      duration: '.2',
    }, '-=.5').to('.main-menu__items__list', {
      duration: .2,
      y: '40px',
      alpha: 0,
    }, '-=.3').to('.main-menu__items', {
      duration: '.4',
      opacity: 0,
    }).to('.main-menu__items', {
      display: 'none',
    });

    tl2.to(getItemsParent, {
      duration: .2,
      x: '100%',
    }).to(getItemsParent, {
      duration: .2,
      display: 'none',
    });


    gsap.utils.toArray('.split-up').forEach((item, i) => {
      const parentSplit = new SplitText(item, {
        linesClass: 'split-parent',
      });
      const childSplit = new SplitText(item, {
        type: 'lines, chars',
        linesClass: 'split-child',
      });
      gsap.from(parentSplit.lines, {
        duration: 1,
        yPercent: 100,
        // stagger: 0.1,
        scrollTrigger: {
          trigger: item,
          toggleActions: 'restart none none reset',
          // start: "top 60%",
          // markers: true
        },
      });
    });


    const box = document.querySelectorAll('.box');
    const boxplus = document.querySelectorAll('.boxr');
    const cta = document.querySelectorAll('.cta');
    const box_up = document.querySelectorAll('.box-up');
    const box_down = document.querySelectorAll('.box-down');


    if (window.innerWidth > 767) {


      box.forEach((el, index) => {
        gsap.fromTo(el, {
          y: -150,
          ease: 'none',
        }, {
          y: 0,
          autoAlpha: 1,
          ease: 'power2',
          duration: 1,
          scrollTrigger: {
            id: `${index + 1}`,
            trigger: el,
            scrub: true,
          },
        });
      });
      box_up.forEach((el, index) => {
        gsap.fromTo(el, {
          y: -250,
          ease: 'none',
        }, {
          y: 0,
          autoAlpha: 1,
          ease: 'power2',
          duration: 1,
          scrollTrigger: {
            id: `${index + 1}`,
            trigger: el,
            scrub: true,
          },
        });
      });
      box_down.forEach((el, index) => {
        gsap.fromTo(el, {
          y: 250,
          ease: 'none',
        }, {
          y: 0,
          autoAlpha: 1,
          ease: 'power2',
          duration: 1,
          scrollTrigger: {
            id: `${index + 1}`,
            trigger: el,
            scrub: true,
          },
        });
      });

      boxplus.forEach((el, index) => {
        gsap.fromTo(el, {
          y: 150,
          ease: 'none',
        }, {
          y: 0,
          autoAlpha: 1,
          ease: 'power2',
          duration: 1,
          scrollTrigger: {
            id: `${index + 1}`,
            trigger: el,
            scrub: true,
          },
        });
      });
      cta.forEach((el, index) => {
        gsap.fromTo(el, {
          y: -50,
          ease: 'none',
        }, {
          y: 0,
          autoAlpha: 1,
          ease: 'power2',
          duration: 1,
          scrollTrigger: {
            id: `${index + 1}`,
            trigger: el,
            scrub: true,
          },
        });
      });


    }

  }, [getPost, dispath]);


  return (
    <HelmetProvider>
      <Helmet>
        <title>{`${page_data?.title ? page_data?.title + ' | ' : ''}`}Formonic Design & Construction Ltd</title>

        {
          page_data?.meta_description &&
          <meta name='description' content={page_data?.meta_description} />

        }
        {
          page_data?.og_title &&
          <meta property='og:title' content={page_data?.og_title} />

        }
        {
          page_data?.og_description &&
          <meta property='og:description' content={page_data?.og_description} />

        }

      </Helmet>

      <StyledComponent>

        <InnerBanner notFull title={getPost?.posts?.data?.page_data?.title}
                     subtext={getPost?.posts?.data?.page_data?.short_desc}
                     img={getPost?.posts?.data?.page_images?.list?.[0]?.full_path ? getPost?.posts?.data?.page_images?.list?.[0]?.full_path : 'images/static/blur.jpg'} />

        <section className='project-filter'>
          <Container>
            <Row>
              <Col md={4}>
                <SelectField placeholder={'Type'}
                             selection={type}
                             onSelect={handleTypeSelect}
                />
              </Col>
              <Col md={4} className={'status_field'}>
                <SelectField placeholder={'Status'}
                             selection={category}
                             onSelect={handleStatusSelect}
                />
              </Col>
              <Col md={4}>
                <SelectField placeholder={'Location'}
                             selection={location}
                             onSelect={handleLocationSelect}
                />
              </Col>
            </Row>
          </Container>
        </section>
        <section className='project-list'>
          <Container>
            <Row>
              {
                filteredData && filteredData?.length > 0 ?
                  filteredData?.map((e, index) => {
                    const thumb = e?.images?.list?.find(f => f?.thumb === 'on');
                    return (
                      <Col md={3} key={index} className={'project-list-col'}>
                        <ProjectBox link={`/project/${e?.product_data?.slug}`}
                                    title={e?.product_data?.title}
                                    type={e?.product_data?.type_title}
                                    location={e?.product_data?.location}
                                    katha={e?.product_data?.katha}
                                    img={thumb?.full_path ? thumb?.full_path : '/images/static/blurbg.svg'} />
                      </Col>
                    );
                  })
                  :
                  'No projects found...'
              }
            </Row>
          </Container>
        </section>
      </StyledComponent>
    </HelmetProvider>

  );
};

const StyledComponent = styled.section`
  background: #f1eee9;

  .project-filter {
    background: #f1eee9;
    padding-top: 60px;
  }

  .project-list {
    background: #f1eee9;
    padding-top: 60px;
    padding-bottom: 120px;

    .project-list-col {
      margin-bottom: 60px;
    }
  }

  @media (max-width: 992px) and (min-width: 768px) {
    .project-list-col {
      flex: 0 0 50% !important;
      max-width: 50% !important;
    }
  }

  @media (max-width: 767px) {
    .project-list {
      padding-bottom: 80px;

      .project-list-col {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;

export default MyComponent;
