import {configureStore} from "@reduxjs/toolkit";
import postReducer from '../redux/post'
import globalReducer from '../redux/global'
import homeReducer from '../redux/home'
import aboutReducer from '../redux/about'
import careerReducer from '../redux/career'
import concernReducer from '../redux/concern'
import consultancyReducer from '../redux/consultancy'
import contactReducer from '../redux/contact'
import leadershipReducer from '../redux/leadership-team'
import projectsReducer from '../redux/projects'
import servicesReducer from '../redux/services'
import loaderReducer from '../redux/loader'
import rootReducer from '../redux/root'

const store = configureStore({
    reducer: {
        postReducer,
        globalReducer,
        homeReducer,
        aboutReducer,
        careerReducer,
        concernReducer,
        consultancyReducer,
        contactReducer,
        leadershipReducer,
        projectsReducer,
        servicesReducer,
        loaderReducer,
        rootReducer
    },
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
})

export default store

