import {useEffect} from "react";
import ErrorPage from "../components/error/404";
import gsap, {CSSPlugin, TimelineLite} from "gsap";
gsap.registerPlugin(CSSPlugin);

const Home = () => {


    // for close menu
    let tl2 = new TimelineLite();
    useEffect(() => {
        document?.querySelector('.main-menu')?.classList.remove('menu-is-open')
        document.body.classList.remove('stop-scroll')
        let getItemsParent = document.querySelector('.main-menu-mobile__items');

        if (document.querySelector('.main-item.active')) {
            getItemsParent.classList.remove('active')
            document.querySelector('.main-item.active').classList.remove('active')
        }

        tl2.to('.main-menu__items__list', {
            duration: .2,
            y: '-40px',
            alpha: 0
        }).to('.main-menu__items', {
            opacity: '0',
            duration: '.3'
        }).to('.main-menu__items__img', {
            opacity: '0',
            duration: '.2'
        }, '-=.5').to('.main-menu__items__list', {
            duration: .2,
            y: '40px',
            alpha: 0
        }, '-=.3').to('.main-menu__items', {
            duration: '.4',
            opacity: 0
        }).to('.main-menu__items', {
            display: 'none'
        })

        tl2.to(getItemsParent, {
            duration: .2,
            x: '100%'
        }).to(getItemsParent, {
            duration: .2,
            display: 'none'
        })


    })


    return (
        <>
            <ErrorPage/>
        </>
    )


}


export default Home;



