import React, {useEffect} from 'react';
import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import  {fetchPosts} from "../../api/redux/about";
import {ApiParam} from "../../api/network/apiParams";
import OverviewInner from "../../components/OverviewInner";
import MissionVision from "../../components/MissionVision";
import CenterTextImage from "../../components/CenterTextImage";
import ManagingDirectors from "../../components/ManagingDirectors";
import Peoples from "../../components/Peoples";
import PeoplesMobile from "../../components/PeoplesMobile";
import CoreValues from "../../components/CoreValues";
import { gsap,CSSPlugin, TimelineLite} from "gsap";
gsap.registerPlugin( CSSPlugin);

const MyComponent = () => {

    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS
        let param = {
            [ApiParam.TYPE]: 'slug',
            [ApiParam.VALUE]: 'about-us',
            [ApiParam.GET_SECTION]: 'yes',
            [ApiParam.IMAGE]: 'yes',
            [ApiParam.POST]: 'yes',
            [ApiParam.GALLERY]: 'yes',
        }
        dispath(fetchPosts([api_url, param]))
    }, [dispath])


    let getPost = useSelector(state => state.aboutReducer)
    let page_data = getPost?.posts?.data?.page_data
    const overview_about = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'pioneers-in-structural-engineering-about');
    const mission_vision = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'mission-vision');
    const corporate_value = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'corporate-values');
    const matter_most = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'you-matter-most');
    const md_message = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'mds-message');
    const peoples = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'our-people');


    // for close menu
    let tl2 = new TimelineLite();
    useEffect(() => {
        document?.querySelector('.main-menu')?.classList.remove('menu-is-open')
        document.body.classList.remove('stop-scroll')
        let getItemsParent = document.querySelector('.main-menu-mobile__items');

        if (document.querySelector('.main-item.active')) {
            getItemsParent.classList.remove('active')
            document.querySelector('.main-item.active').classList.remove('active')
        }

        tl2.to('.main-menu__items__list', {
            duration: .2,
            y: '-40px',
            alpha: 0
        }).to('.main-menu__items', {
            opacity: '0',
            duration: '.3'
        }).to('.main-menu__items__img', {
            opacity: '0',
            duration: '.2'
        }, '-=.5').to('.main-menu__items__list', {
            duration: .2,
            y: '40px',
            alpha: 0
        }, '-=.3').to('.main-menu__items', {
            duration: '.4',
            opacity: 0
        }).to('.main-menu__items', {
            display: 'none'
        })

        tl2.to(getItemsParent, {
            duration: .2,
            x: '100%'
        }).to(getItemsParent, {
            duration: .2,
            display: 'none'
        })






        const box = document.querySelectorAll('.box');
        const boxplus = document.querySelectorAll('.boxr');
        const cta = document.querySelectorAll('.cta');
        const box_up = document.querySelectorAll('.box-up');
        const box_down = document.querySelectorAll('.box-down');



        if (window.innerWidth > 767) {


            box.forEach((el, index) => {
                gsap.fromTo(el, {
                    y: -150,
                    ease: "none",
                }, {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        scrub: true
                    }
                });
            });
            box_up.forEach((el, index) => {
                gsap.fromTo(el, {
                    y: -250,
                    ease: "none",
                }, {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        scrub: true
                    }
                });
            });
            box_down.forEach((el, index) => {
                gsap.fromTo(el, {
                    y: 250,
                    ease: "none",
                }, {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        scrub: true
                    }
                });
            });

            boxplus.forEach((el, index) => {
                gsap.fromTo(el, {
                    y: 150,
                    ease: "none",
                }, {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        scrub: true
                    }
                });
            });
            cta.forEach((el, index) => {
                gsap.fromTo(el, {
                    y: -50,
                    ease: "none",
                }, {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        scrub: true
                    }
                });
            });


        }
    }, [getPost,dispath])


    return (
        <HelmetProvider>
            <Helmet>
                <title>{`${page_data?.title ? page_data?.title + ' | ' : ''}`}Formonic Design & Construction Ltd</title>

                {
                    page_data?.meta_description &&
                    <meta name="description" content={page_data?.meta_description}/>

                }
                {
                    page_data?.og_title &&
                    <meta property="og:title" content={page_data?.og_title}/>

                }
                {
                    page_data?.og_description &&
                    <meta property="og:description" content={page_data?.og_description}/>

                }

            </Helmet>
            <StyledComponent>
                <InnerBanner title={getPost?.posts?.data?.page_data?.title}
                             subtext={getPost?.posts?.data?.page_data?.short_desc}
                             img={getPost?.posts?.data?.page_images?.list?.[0]?.full_path ? getPost?.posts?.data?.page_images?.list?.[0]?.full_path : '/images/static/blurbg.svg'}/>



                <OverviewInner pop={overview_about}
                    title={overview_about?.section_data?.title}
                    subtitle={overview_about?.section_data?.short_desc}
                    description={overview_about?.section_data?.description}
                />
                <MissionVision data={mission_vision}/>
                <CoreValues data={corporate_value}/>
                <CenterTextImage data={matter_most}/>
                <ManagingDirectors data={md_message}/>
                {
                    window?.innerWidth > 767 &&
                    <Peoples data={peoples}/>
                }
                {
                    window?.innerWidth < 767 &&
                    <PeoplesMobile data={peoples}/>
                }
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
