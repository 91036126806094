import React, {useState, useEffect} from 'react';
import {gradient, hover, hoverNd, title} from '../styles/globalStyleVars';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import ReactHtmlParser from "react-html-parser";
import {Col, Container, Row} from "react-bootstrap";
import {Img} from "./Img";
import Accordions from "./service/Accordions";
import Title from "./Title";
import SelectField from "./SelectField";
import GoogleMapsMarkers from "./GoogleMapsMarkers";


const ServicesMap = ({offset, revers, padding, data}) => {

    const type = data && data?.map(item1 => ({
        value: item1?.product_data?.type_title,
        label: item1?.product_data?.type_title,
    }));

    const unique_type = Array.from(new Set(type?.map((option) => option.value)))?.map((value) => type.find((option) => option.value === value));

    const status = data && data?.map(item1 => ({
        value: item1?.product_data?.category_title,
        label: item1?.product_data?.category_title,
    }));

    const unique_status = Array.from(new Set(status?.map((option) => option.value)))?.map((value) => status.find((option) => option.value === value));


    const location = data && data?.map(item1 => ({
        value: item1?.product_data?.location,
        label: item1?.product_data?.location,
    }));

    const unique_location = Array.from(new Set(location?.map((option) => option.value)))?.map((value) => location.find((option) => option.value === value));

    // Define state variables for selected filters
    const [selectedType, setSelectedType] = useState();
    const [selectedStatus, setSelectedStatus] = useState();
    const [selectedLocation, setSelectedLocation] = useState();
    const [filteredData, setFilteredData] = useState(data);

    useEffect(() => {
        // Filter the data based on selected filters
        const newFilteredData = data && data.filter(item => {
            const typeMatch = !selectedType || item.product_data.type_title === selectedType.value;
            const statusMatch = !selectedStatus || item.product_data.category_title === selectedStatus.value;
            const locationMatch = !selectedLocation || item.product_data.location === selectedLocation.value;

            return typeMatch && statusMatch && locationMatch;
        });

        setFilteredData(newFilteredData);
    }, [selectedType, selectedStatus, selectedLocation, data]);

    // Callback function to handle selected type
    const handleTypeSelect = (selectedValue) => {
        setSelectedType(selectedValue);
    };

// Callback function to handle selected status
    const handleStatusSelect = (selectedValue) => {
        setSelectedStatus(selectedValue);
    };

// Callback function to handle selected location
    const handleLocationSelect = (selectedValue) => {
        setSelectedLocation(selectedValue);
    };

    console.log('filteredData', filteredData)

    return (
        <>
            <StyledBuilHome offset={offset} className={`BuilHome ${padding ? padding : ''}`}>
                <div className="noise"></div>
                <Container className={'margin-bottom-fiv'}>
                    <Row>
                        <Col md={12}>
                            <h2>OUR FOOTPRINT</h2>
                        </Col>
                        <Col md={4}>
                            <SelectField
                                placeholder={'Type'}
                                selection={unique_type}
                                onSelect={handleTypeSelect}
                            />
                        </Col>
                        <Col md={4}>
                            <SelectField
                                placeholder={'Status'}
                                selection={unique_status}
                                onSelect={handleStatusSelect}
                            />
                        </Col>
                        <Col md={4}>
                            <SelectField
                                placeholder={'Location'}
                                selection={unique_location}
                                onSelect={handleLocationSelect}
                            />
                        </Col>
                    </Row>
                </Container>

            </StyledBuilHome>
            <StyledMap className="map">
                <GoogleMapsMarkers data={filteredData}/>
            </StyledMap>
        </>
    )
};

const StyledBuilHome = styled.div`
  position: relative;
  background: #26201E;
  
  .margin-bottom-fiv{
    padding-bottom: 40px;
  }
  .noise {
    position: absolute;
    inset: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;

    &:after {
      content: '';
      background-image: url('/images/static/background-noise.png');
      width: 100%;
      height: 100%;
      position: absolute;
      inset: 0;
      opacity: 0.2;
    }


  }
  .map{
    height: 100vh;
  }
  h2 {
    color: #F1EEE9;
    margin-bottom: 85px;
  }

  .filter__control {
    border-bottom-color: rgba(241, 238, 233, 0.5) !important;

    .filter__placeholder {
      color: rgba(241, 238, 233, 0.5);
    }
  }

  .filter__control.filter__control--is-focused .filter__indicator svg line {
    stroke: rgba(241, 238, 233, 0.5) !important;
  }

  .filter__control .filter__single-value{
    color: rgba(241, 238, 233, 0.5);

  }

  .filter__control.filter__control--is-focused{
    border-bottom-color: rgba(241, 238, 233, 0.5) !important;

    .filter__placeholder {
      color: rgba(241, 238, 233, 0.5);
    }

    .filter__single-value{
      color: rgba(241, 238, 233, 0.5);
    }
  }

  .filter__control {
    border-bottom-color: rgba(241, 238, 233, 0.5) !important;

    .filter__placeholder {
      color: rgba(241, 238, 233, 0.5);
    }
  }

  .filter__control.filter__control--is-focused .filter__indicator svg line {
    stroke: rgba(241, 238, 233, 0.5) !important;
  }


`;
const StyledMap = styled.div`
 
  height: 100vh;

  .gm-style .gm-style-iw-c {
    position: absolute;
    box-sizing: border-box;
    overflow: hidden;
    top: 0;
    left: 0;
    transform: translate3d(-50%,-100%,0);
    background-color: #C0845E;
    padding: 12px;
    box-shadow: 0 2px 7px 1px rgba(0,0,0,.3);
    min-width: 370px !important;
    min-height: 125px;
    padding: 40px 20px 20px;
    ul{
      display: flex;
      li{
        font-size: 12px;
        font-weight: 500;
        color: #F1EEE9;
        text-transform: uppercase;
        position: relative;
        padding-left: 20px;
        &:first-child{
          padding-left: 0;
        }
        &:last-child{
          &:after{
            display: none;
          }
        }
        &:after{
          content: '';
          height: 5px;
          width: 5px;
          background: #F1EEE9;
          position: absolute;
          right: -12px;
          top: 5px;
          border-radius: 50%;
          
        }
     
      }
    }

    p{
      font-size: 20px;
      font-weight: 500;
      color: #F1EEE9;
      width: 85%;
      line-height: 25px;
    }
  }
  .gm-ui-hover-effect>span {
    background-color: #F9F5F2;
  }
  .gm-style .gm-style-iw-tc::after{
    background-color: #C0845E;

  }
  .gm-style .gm-style-iw-d{
    background-color: #C0845E;
    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  @media(max-width: 767px){
    height: 70vh;
  }
`

export default React.memo(ServicesMap);
