import React from 'react';
import styled from "styled-components";
import {Accordion} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
import {BsChevronDown} from "react-icons/bs";
import {body_font, hover} from "../styles/globalStyleVars"

const Accordions = ({data}) => {
    return (
        <StyledAddress className='address'>
            <div className={`address__accordion`} sm={12}>
                {
                    data &&
                    <Accordion defaultActiveKey="0">
                        {
                            data?.length > 0 && data?.map((e,i)=>{
                                return(
                                    <Accordion.Item key={i} eventKey={`${i}`}>
                                        <Accordion.Header>
                                            {reactHtmlParser(e?.data?.title)}
                                            <span><BsChevronDown/></span>
                                        </Accordion.Header>
                                        <Accordion.Body>

                                            <div className="formonic-address">
                                                {
                                                    reactHtmlParser(e?.data?.description)
                                                }
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                );
                            })
                        }
                    </Accordion>
                }
            </div>
        </StyledAddress>
    );
};


const StyledAddress = styled.section`

  .accordion-item {
    margin-bottom: 0px;

    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  .accordion-header {
    position: relative;
    margin: 0;
    font-size: unset;
    line-height: unset;

    button {
      border: none;
      background-color: transparent;
      padding-left: 0;
      color: ${hover};
      padding-bottom: 15px;
      padding-top: 15px;
      border-bottom: 1px solid ${hover};
      width: 100%;
      text-align: left;
      transition: all .4s ease;
      font-family: ${body_font};
      font-size: 20px;
      line-height: 32px;
      font-weight: 500;

      &.collapsed {
        color: #7E7576;
        border-color: rgb(126, 117, 118);

        span {
          background-color: transparent;
          color:#7E7576;
          border: 1px solid #7E7576;

          svg {
            transform: rotate(0deg);
          }

          &:after {
            background-color: #26201E !important;
          }
        }
      }
    }

    span {
      position: absolute;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      color: #fff;
      border: 1px solid #26201E;
      background-color: #26201E;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 16px;

      &:after {
        height: 0;
        width: 0;
        background-color: #26201E;
        border-radius: 50%;
        opacity: 0;
        transition: all .4s ease;
        content: '';
        position: absolute;
      }

      svg {
        font-size: 13px;
        z-index: 2;
        transform: rotate(180deg);
      }

    }

    &:hover {
      span {
        color: white !important;
        border-color: #26201E !important;
        &:after {
          height: 100%;
          width: 100%;
          opacity: 1;
        }
      }

      button {
        color: ${hover};
        border-color: ${hover};
      }
    }
  }

  .accordion-body {
    padding-top: 30px;
    padding-bottom: 45px;
    .formonic-content{
      p{
        font-size: 12px;
        line-height: 24px;
        color: #7E7576;
        
      }
    }
  }

  .formonic-address{
    ul{
      display: flex;
      flex-wrap: wrap;
      justify-content: start;
      align-content: flex-start;
      align-items: flex-start;
      gap: 40px;
      margin-bottom: 30px;
      li{
        p{
          margin: 0;
          &:first-child{
            font-size: 12px;
            font-weight: 300;
            line-height: 22px;
          }
          &:last-child{
            font-size: 15px;
            color:#C0845E;
            a{
              font-size: 15px;
              color:#C0845E;
              
            }
          }
        }
        a{
          font-size: 15px;
          color:#C0845E;
          font-weight: 300;
        }
      }
    }
  }


  @media (max-width: 768px) {
    .address__accordion {
      min-width: 100%;
    }

    .address__form {
      min-width: 100%;
      margin-top: 60px;
    }
  }

  @media (max-width: 767px) {

    .formonic-address ul{
      flex-direction: column;
    }
    .address__row {
      flex-direction: column-reverse;
    }

    .address__form {
      margin-top: 0;
      margin-bottom: 60px;
    }

    .contact-numbers {
      flex-wrap: wrap;

      .col-sm-4 {
        min-width: 50%;

        &:nth-last-child(1) {
          margin-top: 30px;
          padding-left: 0;
        }
      }
    }
  }

`;


export default React.memo(Accordions);