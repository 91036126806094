import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import InnerBanner from '../../components/InnerBanner';
import { useDispatch, useSelector } from 'react-redux';
import { apiEndPoints } from '../../api/network/apiEndPoints';
import { fetchPosts } from '../../api/redux/contact';
import { ApiParam } from '../../api/network/apiParams';
import { Col, Container, Row } from 'react-bootstrap';
import MapForm from '../../components/MapForm';
import gsap, { CSSPlugin, TimelineLite } from 'gsap';
import { SplitText } from 'gsap/SplitText';
import reactHtmlParser from 'react-html-parser';

gsap.registerPlugin(SplitText, CSSPlugin);

const Contact = () => {
  const dispath = useDispatch();


  // api call
  useEffect(() => {
    let api_url = apiEndPoints.SECTIONS;
    let param = {
      [ApiParam.TYPE]: 'slug',
      [ApiParam.VALUE]: 'contact-us',
      [ApiParam.GET_SECTION]: 'yes',
      [ApiParam.IMAGE]: 'yes',
      [ApiParam.POST]: 'yes',
      [ApiParam.GALLERY]: 'yes',
    };

    dispath(fetchPosts([api_url, param]));


  }, [dispath]);


  let getPost = useSelector(state => state.contactReducer);
  let page_data = getPost?.posts?.data?.page_data;

  const page = getPost?.posts?.data;
  const office = page?.sections[0]?.posts?.list?.find(f => f?.data?.slug === 'office-address');
  const secondary = page?.sections[0]?.posts?.list?.find(f => f?.data?.slug === 'secondary-address');
  const call = page?.sections[0]?.posts?.list?.find(f => f?.data?.slug === 'call');
  const email = page?.sections[0]?.posts?.list?.find(f => f?.data?.slug === 'email');

  const map = page?.sections[0]?.section_data;


  // animation functions
  let tl2 = new TimelineLite();
  useEffect(() => {
    document?.querySelector('.main-menu')?.classList.remove('menu-is-open');
    document.body.classList.remove('stop-scroll');
    let getItemsParent = document.querySelector('.main-menu-mobile__items');

    if (getItemsParent) {

    }
    if (document.querySelector('.main-item.active')) {
      getItemsParent.classList.remove('active');
      document.querySelector('.main-item.active').classList.remove('active');
    }

    tl2.to('.main-menu__items__list', {
      duration: .2,
      y: '-40px',
      alpha: 0,
    }).to('.main-menu__items', {
      opacity: '0',
      duration: '.3',
    }).to('.main-menu__items__img', {
      opacity: '0',
      duration: '.2',
    }, '-=.5').to('.main-menu__items__list', {
      duration: .2,
      y: '40px',
      alpha: 0,
    }, '-=.3').to('.main-menu__items', {
      duration: '.4',
      opacity: 0,
    }).to('.main-menu__items', {
      display: 'none',
    });

    tl2.to(getItemsParent, {
      duration: .2,
      x: '100%',
    }).to(getItemsParent, {
      duration: .2,
      display: 'none',
    });


    gsap.utils.toArray('.split-up').forEach((item, i) => {
      const parentSplit = new SplitText(item, {
        linesClass: 'split-parent',
      });
      const childSplit = new SplitText(item, {
        type: 'lines, chars',
        linesClass: 'split-child',
      });
      gsap.from(parentSplit.lines, {
        duration: 1,
        yPercent: 100,
        // stagger: 0.1,
        scrollTrigger: {
          trigger: item,
          toggleActions: 'restart none none reset',
          // start: "top 60%",
          // markers: true
        },
      });
    });


    const box = document.querySelectorAll('.box');
    const boxplus = document.querySelectorAll('.boxr');
    const cta = document.querySelectorAll('.cta');
    const box_up = document.querySelectorAll('.box-up');
    const box_down = document.querySelectorAll('.box-down');


    if (window.innerWidth > 767) {


      if (box) {
        box.forEach((el, index) => {
          gsap.fromTo(el, {
            y: -150,
            ease: 'none',
          }, {
            y: 0,
            autoAlpha: 1,
            ease: 'power2',
            duration: 1,
            scrollTrigger: {
              id: `${index + 1}`,
              trigger: el,
              scrub: true,
            },
          });
        });

      }
      if (box_up) {
        box_up.forEach((el, index) => {
          gsap.fromTo(el, {
            y: -250,
            ease: 'none',
          }, {
            y: 0,
            autoAlpha: 1,
            ease: 'power2',
            duration: 1,
            scrollTrigger: {
              id: `${index + 1}`,
              trigger: el,
              scrub: true,
            },
          });
        });

      }
      if (box_down) {
        box_down.forEach((el, index) => {
          gsap.fromTo(el, {
            y: 250,
            ease: 'none',
          }, {
            y: 0,
            autoAlpha: 1,
            ease: 'power2',
            duration: 1,
            scrollTrigger: {
              id: `${index + 1}`,
              trigger: el,
              scrub: true,
            },
          });
        });

      }

      if (boxplus) {
        boxplus.forEach((el, index) => {
          gsap.fromTo(el, {
            y: 150,
            ease: 'none',
          }, {
            y: 0,
            autoAlpha: 1,
            ease: 'power2',
            duration: 1,
            scrollTrigger: {
              id: `${index + 1}`,
              trigger: el,
              scrub: true,
            },
          });
        });

      }
      if (cta) {
        cta.forEach((el, index) => {
          gsap.fromTo(el, {
            y: -50,
            ease: 'none',
          }, {
            y: 0,
            autoAlpha: 1,
            ease: 'power2',
            duration: 1,
            scrollTrigger: {
              id: `${index + 1}`,
              trigger: el,
              scrub: true,
            },
          });
        });

      }


    }

  }, [getPost, dispath]);


  return (
    <HelmetProvider>
      <Helmet>
        <title>{`${page_data?.title ? page_data?.title + ' | ' : ''}`}Formonic Design & Construction Ltd</title>

        {
          page_data?.meta_description &&
          <meta name='description' content={page_data?.meta_description} />

        }
        {
          page_data?.og_title &&
          <meta property='og:title' content={page_data?.og_title} />

        }
        {
          page_data?.og_description &&
          <meta property='og:description' content={page_data?.og_description} />

        }

      </Helmet>

      <StyledComponent>
        <InnerBanner title={page?.page_data?.title} subtext={page?.page_data?.short_desc}
                     img={page?.page_images?.list[0]?.full_path} />
        <section className='contact-section-address pt-120 pb-120'>
          <Container>
            <Row>
              <Col className={'d-flex align-center'} md={4}>
                <svg id='Layer_x0020_1' xmlns='http://www.w3.org/2000/svg' width='29.522' height='32.6'
                     viewBox='0 0 29.522 32.6'>
                  <g id='_334097952' transform='translate(0 0)'>
                    <g id='Group_22918' data-name='Group 22918'>
                      <g id='Group_22913' data-name='Group 22913' transform='translate(6.492)'>
                        <g id='Group_22911' data-name='Group 22911'>
                          <path id='_334098456'
                                d='M18.949,23.072a.632.632,0,0,1-.538-.3c-.411-.655-.966-1.48-1.608-2.433C14.219,16.5,10.68,11.253,10.68,8.271a8.269,8.269,0,1,1,16.539,0c0,2.982-3.539,8.235-6.123,12.068-.642.952-1.2,1.778-1.608,2.433A.627.627,0,0,1,18.949,23.072Zm0-21.8a7,7,0,0,0-7,7c0,2.592,3.552,7.866,5.906,11.357.4.6.769,1.141,1.09,1.632.321-.491.69-1.035,1.09-1.632,2.351-3.491,5.9-8.766,5.9-11.357a7,7,0,0,0-6.993-7Z'
                                transform='translate(-10.68 -0.002)' fill='#c0845e'
                                fill-rule='evenodd' />
                        </g>
                        <g id='Group_22912' data-name='Group 22912'
                           transform='translate(3.749 3.648)'>
                          <path id='_334098360'
                                d='M20.184,13.893a4.521,4.521,0,1,1,4.521-4.521A4.526,4.526,0,0,1,20.184,13.893Zm0-7.771A3.247,3.247,0,1,0,23.432,9.37a3.251,3.251,0,0,0-3.247-3.247Z'
                                transform='translate(-15.664 -4.852)' fill='#c0845e'
                                fill-rule='evenodd' />
                        </g>
                      </g>
                      <g id='Group_22917' data-name='Group 22917' transform='translate(0 9.879)'>
                        <g id='Group_22914' data-name='Group 22914'>
                          <path id='_334098264'
                                d='M12.1,35.858a.667.667,0,0,1-.2-.032L2.483,32.685a.637.637,0,0,1-.435-.6V13.775a.638.638,0,0,1,.838-.6l8.285,2.762a.636.636,0,0,1,.358.3c.321.592.7,1.242,1.117,1.931a.638.638,0,0,1,.093.332V35.221a.636.636,0,0,1-.265.517.66.66,0,0,1-.374.119ZM3.321,31.624l8.142,2.714V18.672c-.348-.573-.653-1.1-.934-1.61L3.321,14.656Z'
                                transform='translate(-2.048 -13.138)' fill='#c0845e'
                                fill-rule='evenodd' />
                        </g>
                        <g id='Group_22915' data-name='Group 22915'
                           transform='translate(9.415 4.72)'>
                          <path id='_334097808'
                                d='M15.2,37.414a.646.646,0,0,1-.371-.119.637.637,0,0,1-.265-.517V20.05a.637.637,0,0,1,1.181-.332c1,1.65,2.1,3.282,3.072,4.722.4.6.769,1.143,1.09,1.632.321-.488.69-1.035,1.09-1.632.971-1.441,2.069-3.072,3.072-4.722a.637.637,0,0,1,1.181.332V33.639a.639.639,0,0,1-.435.6L15.4,37.382a.627.627,0,0,1-.2.032Zm.637-15.157V35.894l8.142-2.714V22.258c-.655,1.013-1.316,1.992-1.923,2.9-.642.952-1.2,1.778-1.608,2.433a.64.64,0,0,1-.541.3.632.632,0,0,1-.539-.3c-.411-.658-.966-1.48-1.608-2.433C17.156,24.25,16.5,23.271,15.84,22.258Z'
                                transform='translate(-14.567 -19.414)' fill='#c0845e'
                                fill-rule='evenodd' />
                        </g>
                        <g id='Group_22916' data-name='Group 22916'
                           transform='translate(18.834 0.713)'>
                          <path id='_334097880'
                                d='M37.141,36.093a.666.666,0,0,1-.2-.032l-9.415-3.138a.637.637,0,0,1-.435-.6V18.73a.638.638,0,0,1,.093-.332,32.836,32.836,0,0,0,2.1-3.926.636.636,0,0,1,.785-.353l7.277,2.425a.637.637,0,0,1,.435.6V35.457a.636.636,0,0,1-.265.517A.646.646,0,0,1,37.141,36.093Zm-8.779-4.234L36.5,34.573V17.607l-6.3-2.1a36.969,36.969,0,0,1-1.846,3.4Z'
                                transform='translate(-27.089 -14.086)' fill='#c0845e'
                                fill-rule='evenodd' />
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>

              </Col>
              <Col md={4}>
                <p className={'split-up'}>{office?.data?.title ? office?.data?.title : ''}</p>
              </Col>
              <Col md={4}>
                {
                  office?.data?.subtitle &&
                  <a className={'split-up'} href={office?.data?.subtitle ? office?.data?.subtitle : '#'} target={'_blank'}>
                    {reactHtmlParser(office?.data?.description ? office?.data?.description : '')}
                  </a>
                }

              </Col>
            </Row>
            <Row>
              <Col className={'d-flex align-center'} md={4}>
                <svg id='Layer_x0020_1' xmlns='http://www.w3.org/2000/svg' width='29.522' height='32.6'
                     viewBox='0 0 29.522 32.6'>
                  <g id='_334097952' transform='translate(0 0)'>
                    <g id='Group_22918' data-name='Group 22918'>
                      <g id='Group_22913' data-name='Group 22913' transform='translate(6.492)'>
                        <g id='Group_22911' data-name='Group 22911'>
                          <path id='_334098456'
                                d='M18.949,23.072a.632.632,0,0,1-.538-.3c-.411-.655-.966-1.48-1.608-2.433C14.219,16.5,10.68,11.253,10.68,8.271a8.269,8.269,0,1,1,16.539,0c0,2.982-3.539,8.235-6.123,12.068-.642.952-1.2,1.778-1.608,2.433A.627.627,0,0,1,18.949,23.072Zm0-21.8a7,7,0,0,0-7,7c0,2.592,3.552,7.866,5.906,11.357.4.6.769,1.141,1.09,1.632.321-.491.69-1.035,1.09-1.632,2.351-3.491,5.9-8.766,5.9-11.357a7,7,0,0,0-6.993-7Z'
                                transform='translate(-10.68 -0.002)' fill='#c0845e'
                                fill-rule='evenodd' />
                        </g>
                        <g id='Group_22912' data-name='Group 22912'
                           transform='translate(3.749 3.648)'>
                          <path id='_334098360'
                                d='M20.184,13.893a4.521,4.521,0,1,1,4.521-4.521A4.526,4.526,0,0,1,20.184,13.893Zm0-7.771A3.247,3.247,0,1,0,23.432,9.37a3.251,3.251,0,0,0-3.247-3.247Z'
                                transform='translate(-15.664 -4.852)' fill='#c0845e'
                                fill-rule='evenodd' />
                        </g>
                      </g>
                      <g id='Group_22917' data-name='Group 22917' transform='translate(0 9.879)'>
                        <g id='Group_22914' data-name='Group 22914'>
                          <path id='_334098264'
                                d='M12.1,35.858a.667.667,0,0,1-.2-.032L2.483,32.685a.637.637,0,0,1-.435-.6V13.775a.638.638,0,0,1,.838-.6l8.285,2.762a.636.636,0,0,1,.358.3c.321.592.7,1.242,1.117,1.931a.638.638,0,0,1,.093.332V35.221a.636.636,0,0,1-.265.517.66.66,0,0,1-.374.119ZM3.321,31.624l8.142,2.714V18.672c-.348-.573-.653-1.1-.934-1.61L3.321,14.656Z'
                                transform='translate(-2.048 -13.138)' fill='#c0845e'
                                fill-rule='evenodd' />
                        </g>
                        <g id='Group_22915' data-name='Group 22915'
                           transform='translate(9.415 4.72)'>
                          <path id='_334097808'
                                d='M15.2,37.414a.646.646,0,0,1-.371-.119.637.637,0,0,1-.265-.517V20.05a.637.637,0,0,1,1.181-.332c1,1.65,2.1,3.282,3.072,4.722.4.6.769,1.143,1.09,1.632.321-.488.69-1.035,1.09-1.632.971-1.441,2.069-3.072,3.072-4.722a.637.637,0,0,1,1.181.332V33.639a.639.639,0,0,1-.435.6L15.4,37.382a.627.627,0,0,1-.2.032Zm.637-15.157V35.894l8.142-2.714V22.258c-.655,1.013-1.316,1.992-1.923,2.9-.642.952-1.2,1.778-1.608,2.433a.64.64,0,0,1-.541.3.632.632,0,0,1-.539-.3c-.411-.658-.966-1.48-1.608-2.433C17.156,24.25,16.5,23.271,15.84,22.258Z'
                                transform='translate(-14.567 -19.414)' fill='#c0845e'
                                fill-rule='evenodd' />
                        </g>
                        <g id='Group_22916' data-name='Group 22916'
                           transform='translate(18.834 0.713)'>
                          <path id='_334097880'
                                d='M37.141,36.093a.666.666,0,0,1-.2-.032l-9.415-3.138a.637.637,0,0,1-.435-.6V18.73a.638.638,0,0,1,.093-.332,32.836,32.836,0,0,0,2.1-3.926.636.636,0,0,1,.785-.353l7.277,2.425a.637.637,0,0,1,.435.6V35.457a.636.636,0,0,1-.265.517A.646.646,0,0,1,37.141,36.093Zm-8.779-4.234L36.5,34.573V17.607l-6.3-2.1a36.969,36.969,0,0,1-1.846,3.4Z'
                                transform='translate(-27.089 -14.086)' fill='#c0845e'
                                fill-rule='evenodd' />
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>

              </Col>
              <Col md={4}>
                <p className={'split-up'}>{secondary?.data?.title}</p>
              </Col>
              <Col md={4}>
                {
                  secondary?.data?.subtitle &&
                  <a className={'split-up'} href={secondary?.data?.subtitle} target={'_blank'}>
                    {
                      reactHtmlParser(secondary?.data?.description)
                    }
                  </a>
                }

              </Col>
            </Row>
            <Row>
              <Col className={'d-flex align-center'} md={4}>
                <svg id='Group_22919' data-name='Group 22919' xmlns='http://www.w3.org/2000/svg'
                     width='27.315' height='27.315' viewBox='0 0 27.315 27.315'>
                  <path id='Path_9009' data-name='Path 9009'
                        d='M27.437,20.661c-.048-.04-5.508-3.924-6.982-3.687-.711.126-1.117.611-1.932,1.582-.131.157-.447.531-.691.8a11.344,11.344,0,0,1-1.5-.612,12.474,12.474,0,0,1-5.755-5.755,11.343,11.343,0,0,1-.612-1.5c.268-.245.643-.561.8-.7.966-.81,1.45-1.217,1.576-1.929C12.6,7.384,8.694,1.927,8.654,1.878A2.084,2.084,0,0,0,7.1,1C5.518,1,1,6.86,1,7.848c0,.057.083,5.888,7.273,13.2,7.306,7.182,13.137,7.265,13.194,7.265.987,0,6.848-4.518,6.848-6.1a2.086,2.086,0,0,0-.879-1.553Zm-6.07,5.827c-.79-.067-5.689-.713-11.807-6.723C3.519,13.617,2.89,8.71,2.827,7.95A24.633,24.633,0,0,1,7.112,2.887c.036.036.085.091.147.162a32.223,32.223,0,0,1,3.254,5.52,10.822,10.822,0,0,1-.925.829,9.135,9.135,0,0,0-1.377,1.295.911.911,0,0,0-.156.684,10.4,10.4,0,0,0,.879,2.4,14.3,14.3,0,0,0,6.6,6.6,10.37,10.37,0,0,0,2.4.88.9.9,0,0,0,.684-.156,9.174,9.174,0,0,0,1.3-1.382c.286-.341.667-.795.812-.923a32,32,0,0,1,5.532,3.258c.076.064.129.113.165.145a24.616,24.616,0,0,1-5.064,4.286Z'
                        transform='translate(-1 -1)' fill='#c0845e' />
                  <path id='Path_9010' data-name='Path 9010'
                        d='M22.463,14.284h1.821A7.292,7.292,0,0,0,17,7V8.821a5.468,5.468,0,0,1,5.463,5.463Z'
                        transform='translate(-2.432 -1.537)' fill='#c0845e' />
                  <path id='Path_9011' data-name='Path 9011'
                        d='M27.016,13.837h1.821A11.85,11.85,0,0,0,17,2V3.821A10.027,10.027,0,0,1,27.016,13.837Z'
                        transform='translate(-2.432 -1.089)' fill='#c0845e' />
                </svg>

              </Col>
              <Col md={4}>
                <p className={'split-up'}>{call?.data?.title}</p>
              </Col>
              <Col md={4}>
                {
                  reactHtmlParser(call?.data?.description)
                }
              </Col>
            </Row>
            <Row>
              <Col className={'d-flex align-center'} md={4}>
                <svg xmlns='http://www.w3.org/2000/svg' width='39.273' height='22.691'
                     viewBox='0 0 39.273 22.691'>
                  <path id='Path_9008' data-name='Path 9008'
                        d='M40.782,24H13.727a3.5,3.5,0,0,0-3.491,3.491V28.8a.873.873,0,0,0,1.745,0V27.491a2.19,2.19,0,0,1,.087-.524l9.775,8.378-9.775,8.378a2.19,2.19,0,0,1-.087-.524V41.891a.873.873,0,1,0-1.745,0V43.2a3.5,3.5,0,0,0,3.491,3.491H40.782A3.5,3.5,0,0,0,44.273,43.2V27.491A3.5,3.5,0,0,0,40.782,24Zm-27.4,1.789a.851.851,0,0,1,.349-.044H40.782a.851.851,0,0,1,.349.044L27.822,37.178a.918.918,0,0,1-1.135,0Zm27.4,19.157H13.727a.851.851,0,0,1-.349-.044L23.2,36.48l2.356,2.051a2.54,2.54,0,0,0,3.4,0l2.356-2.051L41.131,44.9A.851.851,0,0,1,40.782,44.946ZM42.528,43.2a2.19,2.19,0,0,1-.087.524l-9.775-8.378,9.775-8.378a2.19,2.19,0,0,1,.087.524ZM7.618,33.164a.875.875,0,0,1,.873-.873h5.236a.873.873,0,0,1,0,1.745H8.491A.875.875,0,0,1,7.618,33.164ZM13.727,38.4H5.873a.873.873,0,0,1,0-1.745h7.855a.873.873,0,0,1,0,1.745Z'
                        transform='translate(-5 -24)' fill='#c0845e' />
                </svg>


              </Col>
              <Col md={4}>
                <p className={'split-up'}>{email?.data?.title}</p>
              </Col>
              <Col md={4}>
                {reactHtmlParser(email?.data?.description)}
              </Col>
            </Row>
          </Container>
        </section>
        <MapForm data={map} />
      </StyledComponent>
    </HelmetProvider>
  );
};

const StyledComponent = styled.section`

  .contact-section-address {
    background: #F1EEE9;

    .row {
      border-bottom: 1px solid rgba(38, 32, 30, 0.2);
      padding-bottom: 30px;
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
      }
    }

    .d-flex {
      justify-content: center;
    }

    p {
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
      color: #C0845E;
      letter-spacing: 0;
      margin: 0;
    }

    a {
      color: #26201E;
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
      display: block;
      letter-spacing: 0;
    }
  }


  @media (max-width: 767px) {
    .contact-section-address {
      backgrxound: #F1EEE9;

      .row {

      }

      .d-flex {
        justify-content: flex-start;

        svg {
          margin-bottom: 10px;
        }
      }

      p {
        margin-bottom: 20px;
      }


    }

  }
`;

export default Contact;
