import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from "react-bootstrap";
import Title from "./../Title";
import Button from "../Button";
import reactHtmlParser from "react-html-parser";
import ModalOverview from "../ModalOverview";

const OverviewInner = (data) => {

    const overview_data = data?.data?.section_data;
    const [show, setShow] = useState(false);
    const [popupId, setPopupId] = useState()

    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        setShow(true)
        setPopupId(e)
    }

    return (
        <StyledOverviewInner className={`about_overview pt-180 pb-180`}>
            <ModalOverview show={show} data={overview_data} handleClose={handleClose}/>

            <div className="noise"></div>

            <Container>
                <Row>
                    <Col md={{span: 6, offset: 6}}>

                        {
                            overview_data?.title &&
                            <Title margin={'0 0 70px'} text={overview_data?.title}/>

                        }

                    </Col>

                    <Col md={{span: 6, offset: 6}}>

                        <h4 className={'split-up'}>{overview_data?.short_desc ? reactHtmlParser(overview_data?.short_desc) : ''}</h4>

                        {
                            overview_data?.description && (
                                <>
                                    {reactHtmlParser(overview_data.description.slice(0, 700)+'...')}
                                </>
                            )
                        }

                        <div className="button" onClick={() => handleShow()}>
                        <Button src={'#'} margin={'40px 0 0'}
                                color={'#F1EEE9'} icon_border={'#F1EEE9'} text={'LEARN MORE'}/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledOverviewInner>
    )
};

const StyledOverviewInner = styled.div`
  position: relative;
  overflow: hidden;
  background: #2F374B;

  // overview_bg
  .noise {
    position: absolute;
    inset: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;

    &:after {
      content: '';
      //background: #2F374B;
      background-image: url('/images/static/home-about.svg');
      background-repeat: no-repeat;
      //background-blend-mode: multiply;
      background-size: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      inset: 0;
      background-position: center !important;

      @media (max-width: 992px) {
        background-position: bottom;
        background-image: url('/images/static/home-tab.svg');
      }
      @media (max-width: 767px) {
        background-image: url('/images/static/home-mob.svg');

      }
    }

    &:after {
      //animation: Noise_grain 5s infinite linear;
    }


  }


  .icon-one {
    position: absolute;
    z-index: 1;
    left: -60px;
    top: 30px;
    filter: blur(5px);
  }


  .icon-two {
    position: absolute;
    z-index: 1;
    left: 60px;
    top: 60%;
    filter: blur(5px);
  }


  .icon-three {
    position: absolute;
    z-index: 1;
    right: -120px;
    bottom: 0;
    filter: blur(5px);
  }


  h4 {
    color: #C0845E;
    font-size: 15px;
    line-height: 22px;
    font-weight: 300;
    margin-bottom: 40px;
  }

  h2, p {
    color: #F1EEE9;
  }


  @media (max-width: 767px) {
    padding-bottom: 400px !important;

    .icon-one {
      left: -40px;

      img {
        height: 98px;
      }
    }

    .icon-two {
      top: unset;
      bottom: -10px;
      left: -60px;

      img {
        height: 120px;
      }
    }

    .icon-three {
      right: -50px;
      bottom: unset;
      top: 120px;

      img {
        height: 120px;
      }
    }
  }


`;


export default React.memo(OverviewInner);
