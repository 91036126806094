export const text = '#26201E';
export const hover = '#C0845E';
export const title_font = `'Orchidea Pro ExLt', Arial, Helvetica, freesans, sans-serif`;
export const title = `'Orchidea Pro ExLt', Arial, Helvetica, freesans, sans-serif`;
export const body_font = `'Museo Sans', Arial, Helvetica, freesans, sans-serif`;
export const primary_light = "#F1EEE9";
export const primary_wood = "#26201E";
export const black = "#26201E";
export const primay_panton = "#DCC2AA";
export const secondary_dark = "#2F374B";
export const secondary_wood = "#C0845E";