import {  useLocation } from 'react-router-dom';
import GlobalStyle from './styles/globalStyle';
import 'lightgallery.js/dist/css/lightgallery.css';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Menu from './components/Menu';
import Footer from './components/Footer';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { gsap } from 'gsap';
import { SmootherContext } from './components/SmootherContext';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollSmoother } from 'gsap/ScrollSmoother';
import { ImageReveal } from './components/animations/ImageReveal';
import PageLoader from './components/preload/PageLoader';
import Router from './router/Router';
import SocialMessage from './components/SocialMessage';

function App() {

  const location = useLocation();
  const [offset, setOffset] = useState('90');
  const useIsomorphicLayoutEffect = typeof window !== 'undefined'
    ? useLayoutEffect
    : useEffect;
  const main = useRef();
  const smoother = useRef();
  ScrollTrigger.refresh();




  useIsomorphicLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
    const ctx = gsap.context(() => {
      smoother.current = ScrollSmoother.create(
        {
          wrapper: '#smooth-wrapper',
          content: '#smooth-content',
          smooth: window?.innerWidth > 767 ? 3 : false, // how long (in seconds) it takes to "catch up" to the native scroll position
          effects: window?.innerWidth > 767 ? true : false,
          normalizeScroll: {
            allowNestedScroll: true,
          },
          ignoreMobileResize: true,
          preventDefault: true,

        },
      );
      
      ScrollTrigger.config({
        limitCallbacks: true,
        ignoreMobileResize: true,
      });
    }, main);
    return () => {
      ctx.revert();
    };

  }, [location.pathname]);


  // route change scroll top & page class
  useEffect(() => {
    if (location.pathname === '/') {
      document.body.classList.add('in-home-page');
    } else {
      document.body.classList.remove('in-home-page');
    }

    window.scroll(0, 0);
  }, [location.pathname]);
  useEffect(() => {
    if (window.innerWidth > 767) {
      setOffset(document.querySelector('.container').offsetLeft + 15);
    }

  }, [location.pathname]);


  // page transition
  ImageReveal();





  return (
    <>
      <GlobalStyle />
      <ToastContainer position='top-right' autoClose={4000} closeOnClick hideProgressBar={true} />
      <Menu offset={offset} />

      {
        location.pathname === '/' &&
        <PageLoader />
      }
      <SocialMessage/>
      <SmootherContext.Provider value={smoother.current}>
        {/*<Cursor/>*/}
        <div className='App' ref={main} id='smooth-wrapper'>
          <div id='smooth-content'>
            {/*<Switch location={location} key={'location.pathname'}>*/}
            {/*<Route exact path="/" component={Home}/>*/}
            {/*<Route exact path="/components" component={Components}/>*/}
            {/*<Route exact path="/about" component={About}/>*/}
            {/*<Route exact path="/csr" component={CSR}/>*/}
            {/*<Route exact path={`/project`} component={Project}/>*/}
            {/*<Route exact path={`/project/:slug`} component={ProjectDetail}/>*/}
            {/*<Route exact path={`/concerns/:slug`} component={ConcernDetails}/>*/}
            {/*<Route exact path={`/services/:slug`} component={ServicesDetails}/>*/}
            {/*<Route exact path={`/news`} component={News}/>*/}
            {/*<Route exact path={`/news/:slug`} component={NewsDetail}/>*/}
            {/*<Route exact path={`/career`} component={Career}/>*/}
            {/*<Route exact path={`/services`} component={Services}/>*/}
            {/*<Route exact path={`/consultancy`} component={Consultancy}/>*/}
            {/*<Route exact path={`/landowner`} component={Landowner}/>*/}
            {/*<Route exact path={`/for-sale`} component={ForSale}/>*/}
            {/*<Route exact path={`/buyer`} component={Buyer}/>*/}
            {/*<Route exact path={`/concerns`} component={Concern}/>*/}
            {/*<Route exact path={`/contact-us`} component={ContactUs}/>*/}
            {/*<Route exact path={`/global`} component={Components}/>*/}
            {/*<Route component={Error}/>*/}

            <Router />
            {/*</Switch>*/}
            <Footer />

          </div>
        </div>
      </SmootherContext.Provider>
    </>


  );
}


// "start": "react-scripts start",
// "build:production": "react-scripts build --env production",
// "build:staging": "react-scripts build --env staging",
// "test": "react-scripts test",
// "eject": "react-scripts eject"

export default App;
