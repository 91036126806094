import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import Title from "./Title";
import {
    LightgalleryProvider,
    LightgalleryItem,
    withLightgallery,
    useLightgallery
} from "react-lightgallery";
import reactHtmlParser from "react-html-parser";
import {Img} from "./Img";
import {OnlyImg} from "./OnlyImg";

const MyComponent = ({data, gap}) => {

    return (
        <StyledComponent id={'gallery'} gap={gap}  className={'gallery pb-200'}>



            <div className="gallery-title ">
                <Title  color={ '#26201E'}
                       padding={'0 0 40px 0'}
                       center
                       text={reactHtmlParser(data?.data?.title)}/>
            </div>


            <ResponsiveMasonry columnsCount={5}

            >
                {
                    data?.images &&
                    <LightgalleryProvider
                        lightgallerySettings={
                            {
                                download: false,
                                thumbnail: false,
                                fullScreen: true,
                                share: false
                            }
                        }
                    >

                        <Masonry>
                            {
                                data?.images?.length > 0 &&
                                data?.images?.map((e, i)=>{
                                    return(
                                        <LightgalleryItem key={i} group={'any'} src={e?.full_path}>
                                            <OnlyImg alt='' src={e?.full_path} style={{width: "100%"}}/>
                                        </LightgalleryItem>
                                    );
                                })
                            }
                        </Masonry>
                    </LightgalleryProvider>
                }



            </ResponsiveMasonry>

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  overflow: hidden;

  .react_lightgallery_item {
    cursor: pointer;
    border-top: 1px solid #F2EEE8;
    border-right: 1px solid #F2EEE8;
    position: relative;
    img{
      width: 100%;
    }
  }


`;

export default React.memo(MyComponent);
