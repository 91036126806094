import React from 'react';
import styled from "styled-components";
import {Img} from "./Img";
import reactHtmlParser from "react-html-parser";

const CenterTextImage = (data) => {
    return (
        <StyledComponent className={'center-text-images'}>
            <div className="bg-fixed">
                <Img
                    src={data?.data?.images?.list?.[0]?.full_path ? data?.data?.images?.list?.[0]?.full_path : '' }/>
            </div>

            <div className="content">

                    <h3 className={'split-up'}>{data?.data?.section_data?.title ? reactHtmlParser(data?.data?.section_data?.title) : ''}</h3>

                    <p className={'split-up'}>{data?.data?.section_data?.short_desc ? data?.data?.section_data?.short_desc : ''}</p>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  padding-top: calc(580 / 1366 * 100%);

  .bg-fixed {
    position: absolute;
    inset: 0;
  }

  .content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    text-align: center;
    width: 100%;

    h3 {
      font-size: 56px;
      line-height: 60px;
      letter-spacing: 0.5px;
      color: #F1EEE9;
      margin: 0 0 30px;
    }

    p {
      font-size: 12px;
      font-weight: 500;
      line-height: 24px;
      color: #F1EEE9;
    }

  }

  @media (max-width: 767px) {
    padding-top: calc(375 / 375 * 100%);
    .content {
      padding: 0 25px;

      h3 {
        font-size: 40px;
        line-height: 48px;
      }

    }
  }
`;

export default React.memo(CenterTextImage);
