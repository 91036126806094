import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from "react-bootstrap";
import Title from "../Title";
import Button from "../Button";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";

const OverviewInner = ({margin, data}) => {
    return (
        <StyledOverviewInner margin={margin} className={`about_overview pt-180 pb-180`}>
            <div className="noise"></div>
            <div className="icon-two box-up">
                <img src="/images/static/custom_3d_shapes_white_1.svg" alt=""/>
            </div>
            <div className="icon-one box-down ">
                <img src="/images/static/custom_3d_shapes_cube_1.svg" alt=""/>
            </div>

            <Container>
                <Row className={'responsive-class'}>
                    <Col md={{span: 5}} className={'relative'}>
                       <div className="img-wrap-one box-down reveal ">
                           <Img src={data?.images?.list?.find(f => f.short_title === 'top')?.full_path}/>
                       </div>
                        <div className="img-wrap-two box-up ">
                            <Img src={data?.images?.list?.find(f => f.short_title === 'bottom')?.full_path}/>
                        </div>
                    </Col>
                    <Col md={{span: 6, offset: 1}}>
                        <div className="contents">
                            <h2 className={'split-up'}>
                                {reactHtmlParser(data?.section_data?.title)}
                            </h2>
                            {reactHtmlParser(data?.section_data?.description)}
                            <Button margin={'40px 0 0'} src={'/'} color={'#F1EEE9'} icon_border={'#F1EEE9'}
                                    text={'Visit website'}/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledOverviewInner>
    )
};

const StyledOverviewInner = styled.div`
  position: relative;
  //margin-bottom: 300px;
  margin-bottom: ${props => props.margin ? props.margin : '0' };
  .icon-one{
    position: absolute;
    z-index: 1;
    right: -90px;
    top: 70%;
    filter: blur(5px);
  }
  .icon-two {
    position: absolute;
    z-index: 1;
    left: -100px;
    top:50px;
    filter: blur(5px);
  }

  .dc-btn{
    width: auto !important;
    a{
      justify-content: flex-start;
    }
  }
  h2{
    color: #F1EEE9;
    margin-bottom: 85px;
    word-wrap: break-word;
  }
  h4, p {
    color: #F1EEE9;
  }

  // overview_bg
  .noise {
    position: absolute;
    inset: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background: #2F374B;

    &:after {
      content: '';
      background-image: url('/images/static/background-noise.png');
      background-blend-mode: multiply;
      //animation: Noise_grain 5s infinite linear;
      //width: calc(100% + 500px);
      //height: calc(100% + 500px);
      width: 100%;
      height: 100%;
      position: absolute;
      inset: 0;
      opacity: 0.3;
    }

    &:after {
      //animation: Noise_grain 5s infinite linear;
    }


  }
  
  .relative{
    position: relative;
  }
  
  .img-wrap-one{
    padding-top: calc(620 / 470 * 100%);
    position: relative;
    top: -80px;
  }
  
  
  .img-wrap-two{
    padding-top: calc(300 / 470 * 100%);
    position: absolute;
    z-index: 5;
    top:100%;
    right: -70px;
    width: 100%
  }

  @media(max-width: 992px) and (min-width: 768px){
    .img-wrap-two{
      top: 50px;
      right: -35px;
    }
  }
  
  @media (max-width: 767px) {
    margin-bottom: 0;

    .img-wrap-two{
      position: relative !important;
      z-index: 5 !important;
      top: -100px !important;
      right: 0 !important;
      width: calc(100% - 40px) !important;
      margin: 0 0 0 auto !important;
    }
    
    .title {
      margin-bottom: 60px;
    }

    .contents {
      margin-bottom: 40px;
      h4 {
        margin-bottom: 40px;

        br {
          display: none;
        }
      }
    }

    margin-bottom: -100px;

  
    .responsive-class{
      flex-direction: column-reverse;
      .relative{
        margin-top: 40px;
      }
    }



  }

`;


export default React.memo(OverviewInner);
