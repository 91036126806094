import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {fetchPosts, fetchPostsFeatures} from "../../api/redux/home";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import styled from "styled-components";
import {ApiParam} from "../../api/network/apiParams";
import {Helmet, HelmetProvider} from "react-helmet-async";
import SuccessSlider from "../../components/SuccessSlider";
import BuildHome from "../../components/BuildHome";
import FeatureSlider from "../../components/FeatureSlider";
import OverviewInner from "../../components/home/OverviewInner";
import Banner from "../../components/home/Banner";
import BannerMobile from "../../components/home/BannerMobile";
import GoogleMap from "../../components/home/GoogleMap";
import gsap, {CSSPlugin, TimelineLite} from "gsap";
import {SplitText} from "gsap/SplitText";
gsap.registerPlugin(SplitText, CSSPlugin);


const Home = () => {

    const dispath = useDispatch()
    let [offset, setOffset] = useState(90)



    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS
        let param = {
            [ApiParam.TYPE]: 'slug',
            [ApiParam.VALUE]: 'home',
            [ApiParam.GET_SECTION]: 'yes',
            [ApiParam.IMAGE]: 'yes',
            [ApiParam.POST]: 'yes',
            [ApiParam.GALLERY]: 'yes',
        }
        dispath(fetchPosts([api_url, param]))
    }, [dispath])
    let getPost = useSelector(state => state.homeReducer)
    let page_data = getPost?.posts?.data?.page_data
    let feature_data = getPost?.posts?.featured_products?.list


    useEffect(() => {
        if (window.innerWidth > 767) {
            setOffset(document.querySelector('.container').offsetLeft + 0)
        }
    }, [dispath])


    const banner = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'home-banner');
    const overview = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'pioneers-in-structural-engineering');
    const build_home = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'we-build-homes');
    const success = getPost?.posts?.success_story?.list;



    // for close menu
    let tl2 = new TimelineLite();
    useEffect(() => {
        document?.querySelector('.main-menu')?.classList.remove('menu-is-open')
        document.body.classList.remove('stop-scroll')
        let getItemsParent = document.querySelector('.main-menu-mobile__items');

        if (document.querySelector('.main-item.active')) {
            getItemsParent.classList.remove('active')
            document.querySelector('.main-item.active').classList.remove('active')
        }

        tl2.to('.main-menu__items__list', {
            duration: .2,
            y: '-40px',
            alpha: 0
        }).to('.main-menu__items', {
            opacity: '0',
            duration: '.3'
        }).to('.main-menu__items__img', {
            opacity: '0',
            duration: '.2'
        }, '-=.5').to('.main-menu__items__list', {
            duration: .2,
            y: '40px',
            alpha: 0
        }, '-=.3').to('.main-menu__items', {
            duration: '.4',
            opacity: 0
        }).to('.main-menu__items', {
            display: 'none'
        })

        tl2.to(getItemsParent, {
            duration: .2,
            x: '100%'
        }).to(getItemsParent, {
            duration: .2,
            display: 'none'
        })



        gsap.utils.toArray('.split-up').forEach((item, i) => {
            const parentSplit = new SplitText(item, {
                linesClass: "split-parent"
            })
            const childSplit = new SplitText(item, {
                type: "lines, chars",
                linesClass: "split-child"
            })
            gsap.from(parentSplit.lines, {
                duration: 1,
                yPercent: 100,
                // stagger: 0.1,
                scrollTrigger: {
                    trigger: item,
                    toggleActions: "restart none none reset",
                    // start: "top 60%",
                    // markers: true
                }
            })
        });


        const box = document.querySelectorAll('.box');
        const boxplus = document.querySelectorAll('.boxr');
        const cta = document.querySelectorAll('.cta');
        const box_up = document.querySelectorAll('.box-up');
        const box_down = document.querySelectorAll('.box-down');



        if (window.innerWidth > 767) {


            box.forEach((el, index) => {
                gsap.fromTo(el, {
                    y: -150,
                    ease: "none",
                }, {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        scrub: true
                    }
                });
            });
            box_up.forEach((el, index) => {
                gsap.fromTo(el, {
                    y: -250,
                    ease: "none",
                }, {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        scrub: true
                    }
                });
            });
            box_down.forEach((el, index) => {
                gsap.fromTo(el, {
                    y: 250,
                    ease: "none",
                }, {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        scrub: true
                    }
                });
            });

            boxplus.forEach((el, index) => {
                gsap.fromTo(el, {
                    y: 150,
                    ease: "none",
                }, {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        scrub: true
                    }
                });
            });
            cta.forEach((el, index) => {
                gsap.fromTo(el, {
                    y: -50,
                    ease: "none",
                }, {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        scrub: true
                    }
                });
            });


        }

    }, [getPost,dispath])



    return (
        <HelmetProvider>
            <Helmet>
                <title>{`${page_data?.title ? page_data?.title : 'Formonic Design & Construction Ltd'}`}</title>

                {
                    page_data?.meta_description &&
                    <meta name="description" content={page_data?.meta_description}/>

                }
                {
                    page_data?.og_title &&
                    <meta property="og:title" content={page_data?.og_title}/>

                }
                {
                    page_data?.og_description &&
                    <meta property="og:description" content={page_data?.og_description}/>

                }

            </Helmet>
            <StyledSection>
                {
                    window.innerWidth > 992 ?
                        <Banner data={banner?.posts?.list} offset={offset}/>
                        :
                        <BannerMobile data={banner?.posts?.list}/>
                }
                {   overview &&
                    <OverviewInner data={overview}/>
                }
                {
                    feature_data &&
                    <FeatureSlider data={feature_data}/>
                }
                {
                    feature_data &&
                    <GoogleMap data={feature_data}/>
                }

                {
                    success &&
                    <SuccessSlider data={success} padding={'pt-180 pb-180'} bg={'#F1EEE9'}/>
                }

                {
                    build_home &&
                    <BuildHome padding={'pb-180'} data={build_home} offset={offset}/>
                }
            </StyledSection>
        </HelmetProvider>
    );
};

const StyledSection = styled.section`

`;

export default React.memo(Home);
