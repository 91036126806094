import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import {text, title} from "../styles/globalStyleVars";


const Subtitle = ({
                      fontSize,
                      fontWeight,
                      color,
                      letterSpacing,
                      lineHeight,
                      textTransform,
                      margin,
                      padding,
                      borderColor,
                      varient,
                      text,
                      noanim
                  }) => {


    return (

        <StyledSubitle className={`subtitle parallux_sub`}
                       fontSize={fontSize}
                       fontWeight={fontWeight}
                       color={color}
                       lineHeight={lineHeight}
                       LetterSpacing={letterSpacing}
                       textTransform={textTransform}
                       margin={margin}
                       padding={padding}
                       varient={varient}
                       borderColor={borderColor}>
            {text ? <p className={`subtitle ${noanim ? noanim : 'fade-up'} `}>{ReactHtmlParser(text)}</p> : "" }

        </StyledSubitle>

    )
};


const StyledSubitle = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .subtitle{
    text-transform: ${props => props.textTransform || 'Capitalize'};
    font-weight: ${props => props.fontWeight || '400'};
    color: ${props => props.color || text};
    font-family: ${title};
    margin: ${props => props.margin || '0px'};

    font-size: ${props => props.fontSize+'px' || '16px'};
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    padding-bottom: 20px;
    span{
      display: block;
    }
  }
  &:after{
    position: absolute;
    left: 0;
    width: 100%;
    content: "";
    background: #4F616B;
    height: 1px;
    bottom: 20px;
  }


  @media (max-width: 767px) {
    margin-bottom: 40px;
    //flex-direction: column-reverse;
    display: grid;
    grid-auto-flow: row;
    .subtitle{
      order: 1;
      margin-bottom: 30px;
    }
  }
`;


export default Subtitle;
