import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';

export const OnlyImg = ({
                        src,
                        position,
                        objectFit,
                        height,
                        width,
                        banner,
                        alt,
                        left,
                        margin,
                        right,
                        top,
                        bottom,
                    }) => {
    const [imageSrc, setImageSrc] = useState(null);
    const imgRef = useRef(null);

    const DefaultBanner = "/images/static/blurbg.svg";

    const onImageLoad = () => {
        gsap.to(imgRef.current, { opacity: 1, duration: 0.5 });
    };

    const handleIntersection = (entries, observer) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                // Image is in the viewport
                if (imageSrc !== src) {
                    // Check if src is not already set
                    setImageSrc(src);
                }
                observer.unobserve(entry.target); // Stop observing once the image is in view
            }
        });
    };

    useEffect(() => {
        const options = {
            root: null, // viewport
            rootMargin: '0px',
            threshold: 0.0000001, // 10% of the image must be in the viewport
        };

        const observer = new IntersectionObserver(handleIntersection, options);

        if (imgRef.current) {
            observer.observe(imgRef.current);
        }

        return () => {
            // Cleanup the observer when the component unmounts
            observer.disconnect();
        };
    }, [src]);

    return (
        <StyledImg
            objectFit={objectFit}
            margin={margin}
            position={position}
            left={left}
            right={right}
            top={top}
            bottom={bottom}
            height={height}
            width={width}
        >
            <img
                ref={imgRef}
                src={imageSrc || DefaultBanner}
                alt={alt || ''}
                onLoad={onImageLoad}
                style={{ opacity: 0 }} // Initially set opacity to 0
            />
        </StyledImg>
    );
};

const StyledImg = styled.div`
  height: ${(props) => props.height || 'auto'};
  width: ${(props) => props.width || 'auto'};
  right: ${(props) => props.right || 0};
  margin: ${(props) => props.margin || 0};

}`;
