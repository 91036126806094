import React from 'react';
import styled from "styled-components";
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import NavigationIcon from '../NavigationIcon';

const Navigatioin = () => {
    return (
        <StyledNavigatioin>
          <div data-lag={'0.2'} className="navigation">
            <Container className="">
              <Row>
                <Col className={'project-list drawSVG'} md={{span: 6, offset: 6}}>
                  <ul>
                    <li>
                      <Link to={'project?status=Ongoing'}>
                        <svg className={''} xmlns="http://www.w3.org/2000/svg" width="40"
                             height="40"
                             viewBox="0 0 40 40">
                          <g id="Group_22923" data-name="Group 22923"
                             transform="translate(0)">
                            <g id="Group_22928" data-name="Group 22928"
                               transform="translate(11.333 22.667)">
                              <path id="Path_9029" data-name="Path 9029"
                                    d="M136,272h1.333v1.333H136Zm0,0"
                                    transform="translate(-136 -272)" fill="#f1eee9"/>
                              <path id="Path_9030" data-name="Path 9030"
                                    d="M168,272h1.333v1.333H168Zm0,0"
                                    transform="translate(-165.333 -272)" fill="#f1eee9"/>
                              <path id="Path_9031" data-name="Path 9031"
                                    d="M200,272h1.333v1.333H200Zm0,0"
                                    transform="translate(-194.667 -272)" fill="#f1eee9"/>
                              <path id="Path_9032" data-name="Path 9032"
                                    d="M136,304h1.333v1.333H136Zm0,0"
                                    transform="translate(-136 -301.333)" fill="#f1eee9"/>
                              <path id="Path_9033" data-name="Path 9033"
                                    d="M168,304h1.333v1.333H168Zm0,0"
                                    transform="translate(-165.333 -301.333)"
                                    fill="#f1eee9"/>
                              <path id="Path_9034" data-name="Path 9034"
                                    d="M200,304h1.333v1.333H200Zm0,0"
                                    transform="translate(-194.667 -301.333)"
                                    fill="#f1eee9"/>
                              <path id="Path_9035" data-name="Path 9035"
                                    d="M232,304h1.333v1.333H232Zm0,0"
                                    transform="translate(-224 -301.333)" fill="#f1eee9"/>
                              <path id="Path_9036" data-name="Path 9036"
                                    d="M264,304h1.333v1.333H264Zm0,0"
                                    transform="translate(-253.333 -301.333)"
                                    fill="#f1eee9"/>
                              <path id="Path_9037" data-name="Path 9037"
                                    d="M136,336h1.333v1.333H136Zm0,0"
                                    transform="translate(-136 -330.667)" fill="#f1eee9"/>
                              <path id="Path_9038" data-name="Path 9038"
                                    d="M168,336h1.333v1.333H168Zm0,0"
                                    transform="translate(-165.333 -330.667)"
                                    fill="#f1eee9"/>
                              <path id="Path_9039" data-name="Path 9039"
                                    d="M200,336h1.333v1.333H200Zm0,0"
                                    transform="translate(-194.667 -330.667)"
                                    fill="#f1eee9"/>
                              <path id="Path_9040" data-name="Path 9040"
                                    d="M232,336h1.333v1.333H232Zm0,0"
                                    transform="translate(-224 -330.667)" fill="#f1eee9"/>
                              <path id="Path_9041" data-name="Path 9041"
                                    d="M264,336h1.333v1.333H264Zm0,0"
                                    transform="translate(-253.333 -330.667)"
                                    fill="#f1eee9"/>
                              <path id="Path_9042" data-name="Path 9042"
                                    d="M136,368h1.333v1.333H136Zm0,0"
                                    transform="translate(-136 -360)" fill="#f1eee9"/>
                              <path id="Path_9043" data-name="Path 9043"
                                    d="M168,368h1.333v1.333H168Zm0,0"
                                    transform="translate(-165.333 -360)" fill="#f1eee9"/>
                              <path id="Path_9044" data-name="Path 9044"
                                    d="M200,368h1.333v1.333H200Zm0,0"
                                    transform="translate(-194.667 -360)" fill="#f1eee9"/>
                              <path id="Path_9045" data-name="Path 9045"
                                    d="M232,368h1.333v1.333H232Zm0,0"
                                    transform="translate(-224 -360)" fill="#f1eee9"/>
                              <path id="Path_9046" data-name="Path 9046"
                                    d="M264,368h1.333v1.333H264Zm0,0"
                                    transform="translate(-253.333 -360)" fill="#f1eee9"/>
                              <path id="Path_9047" data-name="Path 9047"
                                    d="M136,400h1.333v1.333H136Zm0,0"
                                    transform="translate(-136 -389.333)" fill="#f1eee9"/>
                              <path id="Path_9048" data-name="Path 9048"
                                    d="M168,400h1.333v1.333H168Zm0,0"
                                    transform="translate(-165.333 -389.333)"
                                    fill="#f1eee9"/>
                              <path id="Path_9049" data-name="Path 9049"
                                    d="M200,400h1.333v1.333H200Zm0,0"
                                    transform="translate(-194.667 -389.333)"
                                    fill="#f1eee9"/>
                              <path id="Path_9050" data-name="Path 9050"
                                    d="M232,400h1.333v1.333H232Zm0,0"
                                    transform="translate(-224 -389.333)" fill="#f1eee9"/>
                              <path id="Path_9051" data-name="Path 9051"
                                    d="M264,400h1.333v1.333H264Zm0,0"
                                    transform="translate(-253.333 -389.333)"
                                    fill="#f1eee9"/>
                              <path id="Path_9052" data-name="Path 9052"
                                    d="M136,432h1.333v1.333H136Zm0,0"
                                    transform="translate(-136 -418.667)" fill="#f1eee9"/>
                              <path id="Path_9053" data-name="Path 9053"
                                    d="M168,432h1.333v1.333H168Zm0,0"
                                    transform="translate(-165.333 -418.667)"
                                    fill="#f1eee9"/>
                              <path id="Path_9054" data-name="Path 9054"
                                    d="M200,432h1.333v1.333H200Zm0,0"
                                    transform="translate(-194.667 -418.667)"
                                    fill="#f1eee9"/>
                              <path id="Path_9055" data-name="Path 9055"
                                    d="M232,432h1.333v1.333H232Zm0,0"
                                    transform="translate(-224 -418.667)" fill="#f1eee9"/>
                              <path id="Path_9056" data-name="Path 9056"
                                    d="M264,432h1.333v1.333H264Zm0,0"
                                    transform="translate(-253.333 -418.667)"
                                    fill="#f1eee9"/>
                            </g>
                            <path id="Path_9057" data-name="Path 9057"
                                  d="M39.333,2H34.667V.667A.667.667,0,0,0,34,0H27.333a.667.667,0,0,0-.667.667V2h-26A.667.667,0,0,0,0,2.667V5.333A.666.666,0,0,0,.667,6h2V18.667a.666.666,0,0,0,.667.667A.667.667,0,1,1,2.667,20H1.333a1.985,1.985,0,0,0,.51,1.321L.133,23.6l.008.007A.653.653,0,0,0,0,24v2.667a.666.666,0,0,0,.2.471l1.333,1.333a.667.667,0,0,0,.471.2H4.667a.667.667,0,0,0,.471-.2l1.333-1.333a.666.666,0,0,0,.2-.471V24a.653.653,0,0,0-.142-.393l.008-.007-1.71-2.279A1.988,1.988,0,0,0,4,18.114V6H26.667v.667a.666.666,0,0,0,.667.667h1.333V38.667H26V12H24.667v2.667h-4V13.333H19.333v1.333h-4V14H14v.667H10v-2h8.667V11.333H10V8.667H8.667v30H7.609L4.471,35.529a.666.666,0,0,0-.943,0L.391,38.667H0V40H40V38.667h-.52a1.982,1.982,0,0,0-.96-3.256,2,2,0,1,0-3.706,0,1.982,1.982,0,0,0-.96,3.256H32.667V7.333H34a.666.666,0,0,0,.667-.667V6h4.667A.666.666,0,0,0,40,5.333V2.667A.667.667,0,0,0,39.333,2ZM3.667,22l1,1.333H2L3,22Zm1.667,4.391-.943.942H2.276l-.943-.942V24.667h4ZM36.467,3.333l-1.8,1.2v-1.2ZM28,1.333h2.667V4H28ZM10,3.467l1.8,1.2H8.2ZM6,4.534l-1.8-1.2H7.8Zm6.2-1.2h3.6L14,4.534Zm5.8.133,1.8,1.2H16.2Zm2.2-.133h3.6L22,4.534Zm-18.866,0H1.8l2,1.333H1.333ZM24.2,4.667l2-1.333h.467V4.667Zm7.133,31.8-1.2-1.8,1.2-1.8Zm0-16-1.2-1.8,1.2-1.8Zm-1.333.4,1.2,1.8-1.2,1.8Zm0-4.4v-3.6l1.2,1.8Zm0,12.4,1.2,1.8-1.2,1.8Zm1.333-.4-1.2-1.8,1.2-1.8Zm-1.2-17.8,1.2-1.8v3.6ZM30,8.467V7.333h.755ZM24.667,16v6.667h-4V16Zm-5.333,0v5.733L17.8,20.218a.668.668,0,0,0-.467-.193h-2V16ZM14,16v1.111H10V16ZM4,36.943l1.724,1.724H2.276Zm6,1.724V18.445h4v2.25a.666.666,0,0,0,.667.667h2.393l2.474,2.446A.667.667,0,0,0,20,24h4.667V38.667Zm20-1.8,1.2,1.8H30ZM38,38a.667.667,0,1,1,.667-.667A.666.666,0,0,1,38,38Zm-1.333-4a.667.667,0,1,1-.667.667A.667.667,0,0,1,36.667,34Zm-1.333,2.667a.667.667,0,1,1-.667.667A.667.667,0,0,1,35.333,36.667ZM33.333,6H28V5.333h3.333A.666.666,0,0,0,32,4.667V1.333h1.333Zm3.533-1.333,1.8-1.2v1.2Zm0,0"
                                  fill="#c0845e"/>
                          </g>
                        </svg>

                        <p>Ongoing <br/>
                          Projects</p>
                      </Link>
                    </li>
                    <li>
                      <Link to={'project?status=Completed'}>
                        <svg className={''} xmlns="http://www.w3.org/2000/svg"
                             width="40.624" height="40"
                             viewBox="0 0 40.624 40">
                          <g id="Group_22922" data-name="Group 22922"
                             transform="translate(-0.001 -0.001)">
                            <path id="Path_9012" data-name="Path 9012"
                                  d="M131.524,111.867H125.5a.993.993,0,0,0-.992.992v9.833a.993.993,0,0,0,.992.992h6.02a.993.993,0,0,0,.992-.992v-9.833A.993.993,0,0,0,131.524,111.867Zm-3.605,10.627H125.7V120.06h2.216Zm0-3.624H125.7v-2.189h2.216Zm0-3.38H125.7v-2.433h2.216Zm3.407,7h-2.217V120.06h2.217Zm0-3.624h-2.217v-2.189h2.217Zm0-3.38h-2.217v-2.433h2.217Zm0,0"
                                  transform="translate(-114.632 -102.99)" fill="#f1eee9"/>
                            <path id="Path_9013" data-name="Path 9013"
                                  d="M306.568,411.3a.6.6,0,0,0-.6.6v1.429a.6.6,0,1,0,1.19,0v-1.429A.6.6,0,0,0,306.568,411.3Zm0,0"
                                  transform="translate(-281.695 -378.662)" fill="#f1eee9"/>
                            <path id="Path_9014" data-name="Path 9014"
                                  d="M40.619,26.625a.79.79,0,0,0-.3-.524L27.711,16.346a1.579,1.579,0,0,0-1.933,0l-1.907,1.475V16.113a.6.6,0,1,0-1.19,0v2.63L13.171,26.1a.794.794,0,0,0-.144,1.111l.965,1.255a.792.792,0,0,0,1.112.143L26.745,19.6l8.8,6.807v12.4H30.75V37.695a.6.6,0,1,0-1.19,0v1.116H23.929V29.694H29.56v5.2a.6.6,0,0,0,1.19,0V29.5a.993.993,0,0,0-.992-.992H23.73a.993.993,0,0,0-.992.992v9.315H18.216V37.187a.993.993,0,0,0-.992-.992h-.57a3.731,3.731,0,0,0-6.526-3.122,2.793,2.793,0,0,0-1.468-.058A3.687,3.687,0,0,0,5.5,31.192a3.566,3.566,0,0,0-.413.024V11.3l8.8-6.808,8.8,6.808v2.039a.6.6,0,0,0,1.19,0V12.217l1.651,1.278a.792.792,0,0,0,1.112-.144l.007-.009.924-1.248.006-.007a.753.753,0,0,0,.185-.577.787.787,0,0,0-.3-.524L14.849,1.231a1.579,1.579,0,0,0-1.934,0L9.454,3.909v-.75a.992.992,0,0,0,.826-.977V.993A.993.993,0,0,0,9.288,0H4.465a.993.993,0,0,0-.992.992v1.19a.993.993,0,0,0,.826.977V7.9L.31,10.985a.8.8,0,0,0-.3.527.788.788,0,0,0,.157.585l.965,1.255a.787.787,0,0,0,.628.309.8.8,0,0,0,.485-.166l1.651-1.278V31.566a3.685,3.685,0,0,0-1.942,2.378A2.369,2.369,0,0,0,.437,36.15a2.4,2.4,0,0,0,.022.322.987.987,0,0,0-.307.715v1.822A.993.993,0,0,0,1.145,40H38.458a.6.6,0,1,0,0-1.19H36.733V27.333l1.652,1.278a.8.8,0,0,0,.484.166.786.786,0,0,0,.628-.31l.007-.008.925-1.249.006-.008a.754.754,0,0,0,.185-.576ZM12.993,32.919A2.543,2.543,0,0,1,15.43,36.2H12.035a2.773,2.773,0,0,0-.856-2.51,2.554,2.554,0,0,1,1.813-.766ZM4.664,1.191H9.089v.793H4.664Zm3.6,1.983V4.83L5.489,6.977v-3.8ZM1.83,12.309l-.48-.625L13.643,2.172a.39.39,0,0,1,.478,0l12.277,9.5-.469.633L14.246,3.265a.6.6,0,0,0-.728,0Zm15.2,26.5H1.343V37.386H6.825a.6.6,0,1,0,0-1.19h-5.2c0-.015,0-.03,0-.045A1.175,1.175,0,0,1,2.577,35a.6.6,0,0,0,.476-.5A2.469,2.469,0,0,1,7.8,33.964a.594.594,0,0,0,.81.324,1.593,1.593,0,0,1,2.2,1.908H9.681a.6.6,0,1,0,0,1.19h7.345ZM38.791,27.42,27.109,18.381a.6.6,0,0,0-.728,0L14.692,27.425l-.48-.625,12.294-9.512a.389.389,0,0,1,.478,0l12.277,9.5Zm0,0"
                                  transform="translate(0 0)" fill="#c0845e"/>
                          </g>
                        </svg>

                        <p>Completed <br/>
                          Projects</p>
                      </Link>
                    </li>
                    <li>
                      <Link to={'project?status=Upcoming'}>
                        <svg className={''} xmlns="http://www.w3.org/2000/svg"
                             width="33.737" height="40"
                             viewBox="0 0 33.737 40">
                          <g id="Group_22924" data-name="Group 22924"
                             transform="translate(-40.081)">
                            <path id="Path_9061" data-name="Path 9061"
                                  d="M432.355,87.661h2.505v15.656h-2.505Z"
                                  transform="translate(-361.628 -80.812)" fill="#f1eee9"/>
                            <path id="Path_9063" data-name="Path 9063"
                                  d="M432.355,288.065v.726a3.76,3.76,0,0,0,.145,1.032l.739,2.585a.384.384,0,0,0,.739,0l.739-2.585a3.757,3.757,0,0,0,.145-1.032v-.726Z"
                                  transform="translate(-361.628 -265.56)" fill="#c0845e"/>
                            <path id="Path_9064" data-name="Path 9064"
                                  d="M434.859,58.1h-2.505V56.223a.626.626,0,0,1,.626-.626h1.253a.626.626,0,0,1,.626.626V58.1Z"
                                  transform="translate(-361.627 -51.253)" fill="#f1eee9"/>
                            <g id="Group_22927" data-name="Group 22927"
                               transform="translate(40.081 0)">
                              <path id="Path_9065" data-name="Path 9065"
                                    d="M146.715,133.834l5.677-4.257,7.164,5.373a.586.586,0,1,0,.7-.937l-7.515-5.636a.586.586,0,0,0-.7,0l-7.515,5.636a.586.586,0,0,0,.7.938l.315-.236v7.949h-.667a.586.586,0,0,0,0,1.172h15.03a.586.586,0,0,0,0-1.172h-.667v-6.3a.586.586,0,0,0-1.172,0v6.3h-3.212v-5.677a.586.586,0,0,0-.586-.586h-3.758a.586.586,0,0,0-.586.586v5.677h-3.212Zm4.384,3.737h2.586v5.091H151.1Z"
                                    transform="translate(-136.149 -118.238)"
                                    fill="#f1eee9"/>
                              <path id="Path_9066" data-name="Path 9066"
                                    d="M112.226,88.763v3.758a.586.586,0,0,0,1.172,0V89.349h3.172a.586.586,0,1,0,0-1.172h-3.758A.586.586,0,0,0,112.226,88.763Z"
                                    transform="translate(-106.59 -81.288)"
                                    fill="#c0845e"/>
                              <path id="Path_9067" data-name="Path 9067"
                                    d="M325.574,88.763a.586.586,0,0,0-.586-.586h-3.758a.586.586,0,0,0,0,1.172H324.4v3.172a.586.586,0,0,0,1.172,0Z"
                                    transform="translate(-298.726 -81.288)"
                                    fill="#c0845e"/>
                              <path id="Path_9068" data-name="Path 9068"
                                    d="M324.988,309.541a.586.586,0,0,0,.586-.586V305.2a.586.586,0,1,0-1.172,0v3.172h-3.172a.586.586,0,0,0,0,1.172Z"
                                    transform="translate(-298.726 -280.814)"
                                    fill="#c0845e"/>
                              <path id="Path_9069" data-name="Path 9069"
                                    d="M117.155,308.956a.586.586,0,0,0-.586-.586H113.4V305.2a.586.586,0,0,0-1.172,0v3.758a.586.586,0,0,0,.586.586h3.758a.586.586,0,0,0,.586-.586Z"
                                    transform="translate(-106.59 -280.814)"
                                    fill="#c0845e"/>
                              <path id="Path_9070" data-name="Path 9070"
                                    d="M180.2,400.807H168.925a.586.586,0,1,0,0,1.172H180.2a.586.586,0,0,0,0-1.172Z"
                                    transform="translate(-158.319 -369.494)"
                                    fill="#c0845e"/>
                              <path id="Path_9071" data-name="Path 9071"
                                    d="M180.2,440.887H168.925a.586.586,0,1,0,0,1.172H180.2a.586.586,0,0,0,0-1.172Z"
                                    transform="translate(-158.319 -406.443)"
                                    fill="#c0845e"/>
                              <path id="Path_9072" data-name="Path 9072"
                                    d="M114.69,400.807h-1.879a.586.586,0,0,0,0,1.172h1.879a.586.586,0,1,0,0-1.172Z"
                                    transform="translate(-106.589 -369.494)"
                                    fill="#c0845e"/>
                              <path id="Path_9073" data-name="Path 9073"
                                    d="M114.69,440.887h-1.879a.586.586,0,0,0,0,1.172h1.879a.586.586,0,1,0,0-1.172Z"
                                    transform="translate(-106.589 -406.443)"
                                    fill="#c0845e"/>
                              <path id="Path_9074" data-name="Path 9074"
                                    d="M167.257,48.683a.586.586,0,0,0-.586-.586H152.893a.586.586,0,0,0,0,1.172h13.778A.586.586,0,0,0,167.257,48.683Z"
                                    transform="translate(-143.539 -44.339)"
                                    fill="#c0845e"/>
                              <path id="Path_9075" data-name="Path 9075"
                                    d="M72.606,3.758h-.04V1.212A1.214,1.214,0,0,0,71.354,0H41.293a1.214,1.214,0,0,0-1.212,1.212V38.788A1.214,1.214,0,0,0,41.293,40H64.465a.586.586,0,0,0,.414-.172l7.515-7.515a.586.586,0,0,0,.172-.414V30.646a.586.586,0,0,0-1.172,0v.667h-6.3a1.214,1.214,0,0,0-1.212,1.212v3.131a.586.586,0,0,0,1.172,0V32.525a.04.04,0,0,1,.04-.04h5.474l-6.343,6.343H41.293a.04.04,0,0,1-.04-.04V1.212a.04.04,0,0,1,.04-.04h30.06a.04.04,0,0,1,.04.04V3.758h-.04A1.214,1.214,0,0,0,70.142,4.97V23.231a4.343,4.343,0,0,0,.167,1.193L71.417,28.3a.586.586,0,0,0,1.127,0l1.108-3.878a4.344,4.344,0,0,0,.167-1.193V4.97a1.214,1.214,0,0,0-1.212-1.212ZM71.313,7.434h1.333V21.919H71.313Zm.04-2.505h1.253a.04.04,0,0,1,.04.04V6.263H71.313V4.97A.04.04,0,0,1,71.354,4.929ZM72.524,24.1l-.545,1.906L71.435,24.1a3.168,3.168,0,0,1-.122-.871v-.14h1.333v.14A3.169,3.169,0,0,1,72.524,24.1Z"
                                    transform="translate(-40.081 0)" fill="#c0845e"/>
                            </g>
                          </g>
                        </svg>
                        <p>Upcoming <br/>
                          Projects</p>
                      </Link>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Container>
            <NavigationIcon color={'#F9F5F2'} next_id={'banner-next'} prev_id={'banner-prev'}/>

          </div>
        </StyledNavigatioin>
    );
};

const StyledNavigatioin = styled.div`

`;

export default React.memo(Navigatioin);
