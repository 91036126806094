import React, { useState, useEffect, useRef, memo } from 'react';
import styled from 'styled-components';
import { Col, Container, Form, Row } from 'react-bootstrap';
import Button from './Button';
import { toast } from 'react-toastify';
import { Img } from './Img';
import { useForm } from 'react-hook-form';
import { postForm } from '../api/redux/contact';
import { useDispatch, useSelector } from 'react-redux';
import { apiEndPoints } from '../api/network/apiEndPoints';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import GoogleMapsComponent from './GoogleMapsComponent';

const MapForm = ({ padding, data, id }) => {


  const dispatch = useDispatch();
  const responseData = useSelector(state => state.contact);

  const { register, handleSubmit, formState, reset } = useForm({ mode: 'all' });

  //--- form submit
  const success = (msg) => toast.success(msg, {
    position: 'top-right', autoClose: 4000, closeOnClick: true, progress: undefined,

  });

  const error = (msg) => toast.error(msg, {
    position: 'top-right', autoClose: 4000, closeOnClick: true, progress: undefined,

  });

  const handleBlur = () => {
    // Perform onBlur logic here
  };

  const handleChange = () => {
    // Perform onChange logic here
  };
  const [formSubmitted, setFormSubmitted] = useState(false);

  const onSubmit = async (e) => {
    let api_services = apiEndPoints.SUBMITFORM;
    var formData = new FormData();
    formData.append('name', e?.name);
    formData.append('email', e?.email);
    formData.append('phone', e?.phone);
    formData.append('message', e?.message);
    formData.append('form_id', 'contact-form');

    if (e.email !== '' && e.name !== '' && e.phone !== '') {
      // dispatch(postForm([api_services, formData]));
      reset();
      try {
        const response = await dispatch(postForm([api_services, formData]));

        if (response && response.payload.result === 'success') {
          success(response.payload.message);
        } else if (response && response.error && response.error) {
          error(response.payload.message);
        }
      } catch (err) {
        console.error('API Error:', err); // Check for any errors in the console
        error('An error occurred while submitting the form.');
      }
    }
  };
  let count = 0;
  const onError = (errors) => {
    Object.values(errors).forEach((error) => {
      count++;
    });
    if (count > 0) {
      toast.error('please fill out the correct inputs');
    }
    count = 0;
  };


  // useEffect(() => {
  //   if (responseData && responseData?.error !== '') {
  //     error(responseData?.error?.message);
  //     reset();
  //   }
  //   if (responseData && responseData?.success !== '') {
  //     success(responseData?.success);
  //     reset();
  //   }
  // }, [responseData, formSubmitted]);


  return (<StyledListWithForm id={`${id ? id : 'ListWithForm'}`}
                              className={`list_with_form ${padding ? padding : ''} `}>
    <Container fluid>
      <Row>
        <Col md={7} className={'left-col p-0'}>
          <GoogleMapsComponent data={data} />
        </Col>
        <Col className={'p-0'} md={{ span: 5 }}>
          <div className='form_wrapper'>
            <div className='noise'></div>
            <Form className={'form'} onSubmit={handleSubmit(onSubmit, onError)}>

              <input name={'spam_protector'} type='hidden' />
              <input name={'form_id'} value={'contact-form'} type='hidden' />
              <div className='form-group'>
                <Form.Group controlId='formBasicEmail'>

                  <Form.Control
                    className={formState?.errors?.name?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                    {...register('name', {
                      required: 'Name is required',

                    })}
                    type='text'
                    placeholder='Name*'
                  />
                </Form.Group>
              </div>
              <div className='form-group'>
                <Form.Group controlId='formBasicEmail'>

                  <Form.Control
                    className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                    {...register('email', {
                      required: {
                        value: true, message: 'please enter your email',
                      }, pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'please enter a valid email address',
                      },
                    })}
                    type='email'
                    placeholder='Email*' />
                </Form.Group>
              </div>
              <div className='form-group'>
                <Form.Group controlId='formBasicPhone'>

                  <Form.Control
                    className={formState?.errors?.phone?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                    {...register('phone', {
                      required: {
                        value: true, message: 'please enter your phone first',
                      }, pattern: {
                        value: /^01[0-9]{9}$/,
                        message: 'please enter a valid 11 digit phone number',
                      },
                    })}
                    type='number'
                    placeholder='Phone Number*' />
                </Form.Group>
              </div>
              <div className='form-group'>
                <Form.Group controlId='formBasicPhone'>
  <textarea
    className={formState?.errors?.email?.message ? ' form-control-lg textarea form-control' : 'textarea form-control form-control-lg'}
    {...register('message', {
      // required: {
      //     // value:true,
      //     message: 'please enter your Message',
      // },
    })}
    placeholder='Message (Optional)'
    rows={4} // You can adjust the number of rows as needed
  />
                </Form.Group>

              </div>

              <div className={`form-group width-fit`}>
                <div onClick={handleSubmit(onSubmit, onError)}>

                  <Button src={'#'} color={'#F1EEE9'} icon_border={'#F1EEE9'}
                          text={'Submit Query'} />
                </div>

              </div>

            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  </StyledListWithForm>);
};

const StyledListWithForm = styled.div`
  background: #FFFFFF;

  .form_wrapper {
    background: #3C3C3B;
    padding: 60px;
    height: 100%;
    position: relative;
    // overview_bg
    .noise {
      position: absolute;
      inset: 0;
      overflow: hidden;
      width: calc(100% + 500px);
      height: calc(100% + 500px);
      background: #C0845E;

      &:after {
        content: '';
        background-image: url('/images/static/background-noise.png');
        background-blend-mode: multiply;
        //animation: Noise_grain 5s infinite linear;
        //width: calc(100% + 500px);
        //height: calc(100% + 500px);
        width: 100%;
        height: 100%;
        position: absolute;
        inset: -30px 0 0 0;
        opacity: 0.1;
      }


    }

    .left-col {
      div {
        height: 100%;
      }
    }

    @media (max-width: 992px) and (min-width: 768px) {
      padding: 30px;
    }

    form {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-between;
    }

    .textarea {
      min-height: 120px;
    }

    .title {
      font-size: 24px;
      font-weight: 4000;
      line-height: 28px;
      color: #F9F9F9;
      margin: 0 0 40px;
    }

    .form-group {
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }

      svg {
        display: block;
      }

      .form-control {
        box-shadow: none;
        outline: 0;
        border-radius: 0;
        background: transparent;
        height: auto;
        border-left: none;
        border-right: none;
        border-top: none;
        border-color: rgba(241, 238, 233, 0.5);
        padding: 0 0 8px;
        color: #F1EEE9;
        font-size: 12px;
        font-weight: 500;
        line-height: 24px;

        svg {
          display: block;
        }

        &::placeholder {
          color: rgba(241, 238, 233, 0.5);
          font-size: 12px;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }


  }


  @media (max-width: 767px) {
    padding-top: 0;
    padding-bottom: 0;
    .left-col {
      min-height: 60vh;
    }

    .form_wrapper {
      padding: 60px 15px;
      margin-top: 0px;
    }

    .left-col {
      padding-right: 0px;
    }

    .container {
      padding: 0;
    }

    .width-fit {
      display: flex;
    }
  }
`;

export default React.memo(MapForm);
