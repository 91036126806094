import React from 'react';
import styled from 'styled-components';
import {Img} from "./Img";
import {Link} from "react-router-dom";
import reactHtmlParser from "react-html-parser";

const ConcernBox = ({img, title, link, des, logo}) => {


    return (

        <StyledConcernBox className={'concern-list-single'}>
            <div className="wrapper">
                <Link to={link ? link : '/'}>
                    <div className={'img-wrapper '}>
                        <Img src={img ? img : '/images/static/blurbg.svg'}/>
                        <div className="log-pos">
                            <img src={logo ? logo : 'images/dynamic/logo.svg'} alt=""/>
                        </div>
                        <div className="title">
                            <h5>{reactHtmlParser(title ? title : 'Formonic Builders')}</h5>
                            <p>{des ? reactHtmlParser(des) : reactHtmlParser('As a premier developer company, we specialize in creating exceptional spaces that redefine urban living and shape the landscapes of tomorrow.')}</p>
                        </div>
                    </div>
                </Link>
            </div>
        </StyledConcernBox>

    )
};


const StyledConcernBox = styled.div`
  .wrapper {
    position: relative;

    .img-wrapper {
      padding-top: calc(510 / 370 * 100%);
      position: relative;
      overflow: hidden;

      .title {
        position: absolute;
        inset: 0;
        width: 100%;
        margin: 0 auto;
        text-align: center;
        z-index: 1;
        transition: all 0.7s ease-in;

        &:after {
          content: '';
          position: absolute;
          inset: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.4);
          transition: all 0.7s ease-in;
          opacity: 0;
        }

        h5 {
          margin-bottom: 0;
          transition: all 0.7s ease-in;
          font-size: 20px;
          font-weight: 500;
          line-height: 32px;
          color: #F1EEE9;
          position: absolute;
          bottom: 40px;
          z-index: 3;
          right: 0;
          left: 0;
          text-align: center;

        }

        p {
          font-size: 12px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 1px;
          color: #F1EEE9;
          transition: 0.7s all ease;
          position: absolute;
          left: 0;
          right: 0;
          text-align: center;
          top: 70%;
          transform: translate(0%,-50%);
          z-index: 3;
          padding: 0 30px;
          opacity: 0;
        }

     
      }
      
      .log-pos{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        z-index: 2;
        transition: 0.7s all ease;

      }

     .global-image img {
        transform: scale(1.01);
        transition: all 0.3s ease-in;
      }
    }

    a {
      &:hover {
        .log-pos{
          opacity: 0;
        }
        .img-wrapper {
       
         .title{
           &:after{
             opacity: 1;
           }
         }
          p {
            top: 50%;
            opacity: 1;
            transition: 0.7s all ease;
          }

         .global-image img {
            transform: scale(1.05);
          }
        }
      }
    }
  }
`;

export default React.memo(ConcernBox);














