import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';
export const Img = ({
                        src,
                        position,
                        objectFit,
                        height,
                        width,
                        banner,
                        alt,
                        left,
                        margin,
                        right,
                        top,
                        bottom,
                        visible,
                    }) => {
    const [imageSrc, setImageSrc] = useState(null);
    const imgRef = useRef(null);

    const DefaultBanner = "/images/static/blurbg.svg";


    const handleImageLoad = () => {
        // When the main image is loaded, update the state to show it smoothly.
        setImageSrc(src);
    };
    return (
        <StyledImg
            className="global-image"
            objectFit={objectFit}
            margin={margin}
            position={position}
            left={left}
            right={right}
            top={top}
            bottom={bottom}
            height={height}
            width={width}
            ref={imgRef}
        >
            <div className="image-container">
                <LazyLoadImage
                    threshold={0.0000001}
                    visibleByDefault={visible ? true : false}
                    alt={alt || ''}
                    effect="blur"
                    onLoad={handleImageLoad} // Handle main image load event
                    src={src || DefaultBanner} // use normal <img> attributes as props
                />
            </div>
        </StyledImg>
    );
};

const StyledImg = styled.div`
  position: ${(props) => props.position || 'absolute'};
  height: ${(props) => props.height || '100%'};
  width: ${(props) => props.width || '100%'};
  top: ${(props) => props.top || 0};
  left: ${(props) => props.left || 0};
  bottom: ${(props) => props.bottom || 0};
  right: ${(props) => props.right || 0};
  margin: ${(props) => props.margin || 0};
  background-color: #2F374B; /* Set the background color here */

  .image-container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: ${(props) => props.objectFit || 'cover'};
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: opacity 0.3s ease;
    }
  }
}`;
