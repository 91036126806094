import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Modal, Row} from "react-bootstrap";
import Button from "./Button";
import reactHtmlParser from "react-html-parser";

const SustainabilitySingle = ({offset, data, title, desc, img, link}) => {


    return (
        <StyledSustainabilitySingle offset={offset}>
            <Row className={'sustainability'}>
                <Col md={6} className={'sustainability__left'}>
                    <div className="noise"></div>

                    <div className={'sustainability__left__data'}>
                        <div data-lag={0.3} className="content">
                            <h2>{title ? title : ''}</h2>
                            <p className={'text'}>
                                {
                                    desc ? reactHtmlParser(desc) : ''
                                }
                            </p>

                        </div>
                        {
                            link ?
                              link &&  <Button margin={'150px 0 0'} color={'#F1EEE9'} icon_border={'#F1EEE9'} src={link} text={'Explore'}/>

                              : ''

                        }

                        {
                            data?.page_data?.slug ?
                              <Button margin={'150px 0 0'} color={'#F1EEE9'} icon_border={'#F1EEE9'} src={`/services/${data?.page_data?.slug}`} text={'Explore'}/>
:''
                        }

                    </div>
                </Col>
                <Col md={6} className={'sustainability__right '}>
                    <img src={img ? img : ''}/>
                </Col>
            </Row>

        </StyledSustainabilitySingle>
    );
};
const StyledSustainabilitySingle = styled.div`
  .sustainability {
    margin: 0;

    &__left {
      margin-left: 0;
      position: relative;
      overflow: hidden;

      .noise {
        position: absolute;
        inset: 0;
        overflow: hidden;
        width: calc(100% + 500px);
        height: calc(100% + 500px);
        background: #2F374B;

        &:after {
          content: '';
          background-image: url('/images/static/background-noise.png');
          background-blend-mode: multiply;
          //animation: Noise_grain 5s infinite linear;
          //width: calc(100% + 500px);
          //height: calc(100% + 500px);
          width: 100%;
          height: 100%;
          position: absolute;
          inset: -30px 0 0 0;
          opacity: 0.3;
        }

        &:after {
          //animation: Noise_grain 5s infinite linear;
        }


      }

      &__data {
        position: relative;
        padding-left: ${props => props.offset + 0}px;
        padding-right: ${props => props.offset + 0}px;
        margin-top: 200px;
        padding-bottom: 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        ul {
          position: relative;
          left: unset;
          top: unset;
        }

        h2 {
          color: #F1EEE9;
          margin-bottom: 90px;
        }

        p {
          font-size: 15px;
          line-height: 300px;
          line-height: 22px;
          color: #F1EEE9;
        }

      }
    }

    &__right {
      position: relative;
      padding-top: calc(568 / 816 * 100%);

      img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  @media (max-width: 767px) {
    .sustainability {
      flex-direction: column-reverse;
      margin: 0;

      &__left {
        &__data {
          margin-top: 0;
          margin-bottom: 120px;
          padding: 0 15px;

          .title {
            margin-left: 0;
            margin-bottom: 40px;
            font-size: 40px;
            line-height: 40px;
            font-weight: 400;
            margin-top: -75px;
            width: initial;
          }
        }
      }

      &__right {
        position: relative;
        padding-top: calc(375 / 375 * 100%);

        img {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
  @media (max-width: 992px) and (min-width: 768px) {
    .sustainability__left__data {
      padding-right: 40px !important;
      padding-left: 40px !important;
    }
  }
`;


export default SustainabilitySingle;