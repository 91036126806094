import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Modal} from "react-bootstrap";
import {Swiper, SwiperSlide} from 'swiper/react';
import swiper, {Autoplay, Navigation, Pagination, EffectFade} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import SustainabilitySingle from './../../components/SustainabilitySingle'
import NavigationIcon from "./../NavigationIcon";
import Button from "../Button";

const MyComponent = ({margin, id, no_slider, data}) => {


    let [offset, setOffset] = useState(90)

    useEffect(() => {
        if (window.innerWidth > 767) {
            setOffset(document.querySelector('.thisis').offsetLeft + 0)
        }

    }, [])





    const [current, setCurrent] = useState('1')

    const handleSliderCurrent = () => {
        setTimeout(() => {
            if (document.querySelector('.sustain .swiper-pagination-current')) {
                setCurrent(document.querySelector('.sustain .swiper-pagination-current').innerHTML)
            }
        }, 200)
    }

    return (
        <StyledComponent margin={margin} container={offset} id={`${id ? id : "Sustainability"}`} className={`sustain ${no_slider ? 'slider_no' : ''}`}>
            <NavigationIcon color={'#F9F5F2'} next_id={'services-next'} prev_id={'services-prev'}/>
            <Container className={'thisis'}>
                <Row>
                    <Col md={12}>
                        <div className={'sustain__top d-flex'}>
                            <p>Services</p>
                            <p><span>{current}</span>/<span>{data?.length}</span></p>
                        </div>

                    </Col>
                </Row>
            </Container>
            <Container fluid className={`sustain`}>
                {
                    data &&
                    <Swiper
                        Swiper modules={[Autoplay, Pagination, Navigation, EffectFade]}
                        allowTouchMove={true}
                        autoHeight={true}
                        effect={"fade"}
                        slidesPerView={1}
                        pagination={{
                            // el: '.swiper-pagination',
                            type: "fraction",
                        }}
                        navigation={{
                            prevEl: '#services-prev',
                            nextEl: '#services-next',
                        }}
                        onBeforeInit={(swiper) => {
                            swiper.params.navigation.prevEl = '#services-prev';
                            swiper.params.navigation.nextEl = '#services-next';
                        }}

                        loop={true}
                        onSlideChange={(s) => handleSliderCurrent()}
                    >
                        {
                            data?.length>0 &&
                            data?.map((e, i)=>{
                                return(
                                    <SwiperSlide key={i}>
                                        <SustainabilitySingle offset={offset} data={e}
                                        title={e?.page_data?.title}
                                        desc={e?.page_data?.subtitle}
                                        img={e?.images?.list?.find(f => f.short_title === 'slider')?.full_path}
                                        />
                                    </SwiperSlide>
                                );
                            })
                        }
                    </Swiper>
                }

            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  overflow: hidden;

  
  @media (max-width: 767px) {
    .title {
      h2 {
        font-size: 40px;
      }
    }

    .dc-btn{
      margin-top: 40px;
    }
  }

  .reveal {
    overflow: hidden;

    img {
      transform-origin: left;
      inset: 0px;
    }
  }


  .sustainability__left__data{
    padding-left: ${props => props.container + 0}px;
    padding-right: ${props => props.container + 0}px;
  }

  ul {
    position: absolute;
    top: 235px;
    right: ${props => props.container - 15}px;
    z-index: 150;
    display: flex;

    li {
      cursor: pointer;

      svg {
        #Ellipse_4378 {
          transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
          r: 0;
        }

        &:hover {
          #Ellipse_4378 {
            fill: rgb(208, 202, 16);
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
            r: 26px;
          }

          g {
            line {
              stroke: #f9f9f9;
            }
          }
        }
      }
    }

    li:first-child {
      padding-right: 20px;
    }
  }

  .sustain {
    padding-left: 0;
    padding-right: 0;

    &__top {
      position: absolute;
      top: 120px;
      z-index: 150;
      width: 100%;
      display: flex;
      justify-content: space-between;
      .subtitle:after {
        background: #f9f9f9;
      }

      p {
        color: #f9f9f9;
      }
    }

    
  }
  .swiper-pagination {
    opacity: 0;
    visibility: hidden;
  }

  .sustain__top {
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);

    p {
      margin: 0;
    }
  }

  @media (max-width: 767px) {
    .cPlyYs .sustainability__left__data .title {
      h2 {
        font-size: 40px;
        line-height: 45px;
      }

    }

    .sustain {
      &__top {
        top: 40px;
        padding: 0px;
        width: calc(100% - 30px);
      }
    }
  }
  @media (max-width: 767px) {
    ul {
      position: absolute;
      top: 90px;
      left: 15px;
      z-index: 150;
    }

    .sustainability__left__data {
      margin-top: 40px;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }



`;

export default MyComponent;
