import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import Modal from "react-bootstrap/Modal";
import {Col, Container, Form, Row} from "react-bootstrap";
import CloseModal from "./svg/CloseModal";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import Button from '../components/Button';
import {gsap, TimelineLite} from "gsap";
import reactHtmlParser from "react-html-parser";
import {postForm} from "../api/redux/projects";
import {apiEndPoints} from "../api/network/apiEndPoints";

const ModalOverview = ({
                         show,
                         handleClose,
                         no_img,
                         item,
                         title,
                         description,
                         data,
                         subtitle,
                         img
                     }) => {


    const popupData = data;
    const [winWidth, setWinWidth] = useState(true)
    useEffect(() => {
        if (window.innerWidth > 767) {
            setWinWidth(true)
        } else {
            setWinWidth(false)
        }
        window.addEventListener("resize", () => {
            if (window.innerWidth > 767) {
                setWinWidth(true)
            } else {
                setWinWidth(false)
            }
        });
    }, [])



    console.log(data, 'popup data')



    return (

        <StyledModal>
            <Modal
                show={show}
                item={item}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="gph_modal popup-version-one CareerModal"
            >
                <div className="noise"></div>

                <div className="main_scroll" style={{height: '100vh'}}>
                    <Modal.Body>

                        <Container>
                            <Row className={'for-close'}>
                                <div onClick={handleClose} className="modal-close ">
                                    <CloseModal/>
                                </div>

                            </Row>
                        </Container>
                        <Container>
                            <Row>
                                <div className="modal-data modal-content-left d-flex">
                                    <Col sm={12} className=" ">
                                        <h5>{reactHtmlParser(data?.title)}</h5>

                                        <div className="inner-content">
                                            {
                                                data?.short_desc &&
                                                <p>{data?.short_desc ? reactHtmlParser(data?.short_desc) : ''}</p>

                                            }

                                            {reactHtmlParser(data?.description)}
                                        </div>
                                    </Col>



                                </div>
                            </Row>
                        </Container>
                    </Modal.Body>
                </div>
            </Modal>
        </StyledModal>

    )
};


const StyledModal = styled.div`

  .modal-dialog {
    margin: 0;
  }

  h4 {
    letter-spacing: 1.5px;
  }

`;


export default React.memo(ModalOverview);