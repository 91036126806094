import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from "react-bootstrap";
import Title from "../Title";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";

const OverviewInner = ({data}) => {

    return (
        <StyledOverviewInner className={`about_overview pt-180 pb-180`}>
            <div className="noise"></div>
            <Container>
                <Row>
                    <Col md={{span: 6, offset: 6}}>
                        <Title margin={'0 0 90px'} color={'#26201E'} text={reactHtmlParser(data?.section_data?.title)}/>
                    </Col>
                    <Col md={{span: 6}}>
                        <div className="contents">
                            <h4>{reactHtmlParser(data?.section_data?.subtitle)}</h4>
                        </div>
                    </Col>
                    <Col md={{span: 6}}>
                        <div className="contents">
                            <p className={'split-up'}>
                                {
                                    reactHtmlParser(data?.section_data?.description)
                                }
                            </p>

                        </div>
                    </Col>
                </Row>
                <Row>


                </Row>
            </Container>
        </StyledOverviewInner>
    )
};

const StyledOverviewInner = styled.div`
  position: relative;
  overflow: hidden;

  // overview_bg
  .noise {
    position: absolute;
    inset: 0;
    overflow: hidden;
    width: calc(100% + 500px);
    height: calc(100% + 500px);
    background: #DCC2AA;

    &:after {
      content: '';
      background-image: url('images/static/background-noise.png');
      background-blend-mode: multiply;
      //animation: Noise_grain 5s infinite linear;
      //width: calc(100% + 500px);
      //height: calc(100% + 500px);
      width: 100%;
      height: 100%;
      position: absolute;
      inset: -30px 0 0 0;
      opacity: 0.3;
    }

    &:after {
      //animation: Noise_grain 5s infinite linear;
    }


  }


  @media (max-width: 767px) {
    .title {
      margin-bottom: 60px;
    }

    .contents{
      h4{
        margin-bottom: 40px;
        br{
          display: none;
        }
      }
    }


  }

`;


export default React.memo(OverviewInner);
