import React from 'react';
import styled from 'styled-components';
import {hover, text, title, title_ms60} from "../styles/globalStyleVars";
import ReactHtmlParser from "react-html-parser";
import reactHtmlParser from "react-html-parser";

const Title = ({
                   text,
                   fontSize,
                   fontWeight,
                   color,
                   letterSpacing,
                   lineHeight,
                   textTransform,
                   margin,
                   padding,
                   borderColor,
                   varient,
                   center,
                   classname,
                   small_text,noanim
               }) => {


    return (

        <StyledTitle className={`title  ${classname ? classname : ''}`}
                     fontSize={fontSize}
                     fontWeight={fontWeight}
                     color={color}
                     lineHeight={lineHeight}
                     LetterSpacing={letterSpacing}
                     textTransform={textTransform}
                     margin={margin}
                     padding={padding}
                     varient={varient}
                     center={center}
                     borderColor={borderColor}>
            <h2 className={`title ${ noanim ? '' : 'split-up'} `}>{ text ? ReactHtmlParser(text) : ''} </h2>
        </StyledTitle>

    )
};


const StyledTitle = styled.div`
  margin: ${props => props.margin || '0px'};
  position: relative;
  width: 100% !important;
  text-align: ${props => props?.center ? 'center' : ''};
  padding: ${p => p.padding};

  h5 {
    font-size: ${props => props.fontSize || 40}px;
    line-height: ${props => props.lineHeight || 52}px;
    font-weight: ${props => props.fontWeight || '300'};
    color: ${props => props.color || 'inherit'};
    width: 100%;
  }
  h2{
    color: ${props => props.color || 'inherit'};
  }


`;


export default Title;














