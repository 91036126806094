import React, {useEffect, useState, useRef} from 'react';
import {GoogleMap, LoadScript, Marker, InfoWindow, StyledMap} from '@react-google-maps/api';
import Button from "./../Button";
import styled from 'styled-components';
import {
    LightgalleryProvider,
    LightgalleryItem,
    withLightgallery,
    useLightgallery
} from "react-lightgallery";
import {Img} from "../Img";

const GoogleMapsMarkers = ({data}) => {
    const mapStyles = {
        height: '100%',
        width: '100%',
    };

    const defaultCenter = {
        lat: 23.801927, // Replace with marker latitude
        lng: 90.4029675, // Replace with marker longitude
    };

    const [selectedMarker, setSelectedMarker] = useState(null);


    const markers = data && Array.isArray(data) ? data.map((item, index) => {
        return {
            id: index,
            position: {lat: parseFloat(item?.product_data?.latitude), lng: parseFloat(item?.product_data?.longitude)},
            icon: '/images/static/icon.svg',
            content: item?.product_data?.title,
            type: item?.product_data?.type,
            type2: item?.product_data?.location,
            katha: item?.product_data?.katha,
            storied: item?.product_data?.storied,
            facing: item?.product_data?.facing,
            image: item?.images?.list,
            slug: item?.product_data?.slug,
        };
    }) : [];




    const handleMarkerClick = (marker) => {
        setSelectedMarker(marker);
    };

    const handleInfoWindowClose = () => {
        setSelectedMarker(null);
        setSelectedMarkerId(null)

    };


    const customMapStyle = [
        {
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f5f5f5"
                }
            ]
        },
        {
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#616161"
                }
            ]
        },
        {
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "color": "#f5f5f5"
                }
            ]
        },
        {
            "featureType": "administrative.land_parcel",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#bdbdbd"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#eeeeee"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#757575"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#e5e5e5"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#757575"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#dadada"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#616161"
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        },
        {
            "featureType": "transit.line",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#e5e5e5"
                }
            ]
        },
        {
            "featureType": "transit.station",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#eeeeee"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#c9c9c9"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        }
    ]; // Paste your copied JSON style here

    const mapOptions = {
        styles: customMapStyle,
    };


    const [selectedMarkerId, setSelectedMarkerId] = useState(null);
    const handleButtonClick = (selectedId) => {

        setSelectedMarkerId(selectedId)
    };
    const handleButtonClickClose = () => {
        setSelectedMarkerId(null)
    };



    const mapRef = useRef(null);
    const [map, setMap] = useState(false);

    // Use Intersection Observer to detect when the map component is in the viewport
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // Map component is in the viewport, trigger rendering
                    setMap(true)
                    observer.disconnect(); // Disconnect the observer once rendered
                }
            });
        });

        if (mapRef.current) {
            observer.observe(mapRef.current);
        }

        return () => {
            // Clean up the observer when the component unmounts
            if (observer) {
                observer.disconnect();
            }
        };
    }, []);

    return (
        <StyledComponent ref={mapRef}>

            {
                map &&
                <GoogleMap
                    mapContainerStyle={mapStyles}
                    zoom={10}
                    center={defaultCenter}
                    options={mapOptions}
                >
                    {markers.map((marker) => (
                        <Marker
                            key={marker.id}
                            position={marker.position}
                            icon={marker.icon}
                            onClick={() => {
                                handleMarkerClick(marker)
                                handleButtonClick(marker.id)
                            }
                            }
                        >
                        </Marker>
                    ))}
                    {selectedMarker && (
                        <InfoWindow
                            position={selectedMarker.position}
                            onCloseClick={handleInfoWindowClose}
                        >
                            <div>
                                <ul>
                                    <li>{selectedMarker.type}</li>
                                    <li>{selectedMarker.type2}</li>
                                </ul>
                                <p>{selectedMarker.content}</p>
                                {
                                    window?.innerWidth > 767 &&
                                    <div onClick={() => handleButtonClick(selectedMarker.id)} className={'map-button'}
                                         data-id={selectedMarker.id}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16.707" height="11.414"
                                             viewBox="0 0 16.707 11.414">
                                            <g id="Arrow" transform="translate(-1307.5 -242.793)">
                                                <line id="Line_12400" data-name="Line 12400" x2="5" y2="5"
                                                      transform="translate(1318.5 243.5)" fill="none" stroke="#f1eee9"
                                                      stroke-linecap="round" stroke-width="1"/>
                                                <line id="Line_12401" data-name="Line 12401" y1="5" x2="5"
                                                      transform="translate(1318.5 248.5)" fill="none" stroke="#f1eee9"
                                                      stroke-linecap="round" stroke-width="1"/>
                                                <line id="Line_12402" data-name="Line 12402" x2="15"
                                                      transform="translate(1308 248.5)" fill="none" stroke="#f1eee9"
                                                      stroke-linecap="round" stroke-width="1"/>
                                            </g>
                                        </svg>

                                    </div>
                                }
                            </div>
                        </InfoWindow>
                    )}

                </GoogleMap>
            }

            {/*</LoadScript>*/}
            {
                window?.innerWidth > 767 &&
                <LightgalleryProvider
                    lightgallerySettings={
                        {
                            download: false,
                            thumbnail: false,
                            fullScreen: true,
                            share: false
                        }
                    }
                >

                    <div className="google-map-side-gallery">

                        {markers.map((marker) => (
                            <div key={marker.id} data-item={marker.id}
                                 className={`item-single-gallery ${selectedMarkerId === marker.id ? 'active' : ''}`}>
                                <div onClick={handleButtonClickClose} className="close-modal-map">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10.414" height="10.414"
                                         viewBox="0 0 10.414 10.414">
                                        <g id="Group_20065" data-name="Group 20065"
                                           transform="translate(-480.793 -6228.793)">
                                            <line id="Line_12384" data-name="Line 12384" x2="9" y2="9"
                                                  transform="translate(481.5 6229.5)" fill="none" stroke="#f9f5f2"
                                                  stroke-linecap="round" stroke-width="1"/>
                                            <line id="Line_12385" data-name="Line 12385" x1="9" y2="9"
                                                  transform="translate(481.5 6229.5)" fill="none" stroke="#f9f5f2"
                                                  stroke-linecap="round" stroke-width="1"/>
                                        </g>
                                    </svg>
                                </div>

                                <div className="content">
                                    <h4>{marker.content}</h4>
                                    <ul>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 width="20" height="20" viewBox="0 0 20 20">
                                                <clipPath id="clip-path">
                                                    <rect id="Rectangle_5800" data-name="Rectangle 5800" width="20"
                                                          height="20"
                                                          transform="translate(938 6562)" fill="#f1eee9"
                                                          stroke="#707070"
                                                          stroke-width="1"/>
                                                </clipPath>
                                                <g id="Mask_Group_19885" data-name="Mask Group 19885"
                                                   transform="translate(-938 -6562)" clip-path="url(#clip-path)">
                                                    <path id="Path_8892" data-name="Path 8892"
                                                          d="M18.379,6.983a.675.675,0,0,0-.4-.615l-1.4-.628,1.4-.628a.674.674,0,0,0,0-1.23L12.2,1.286l-.014-.006L9.466.06a.674.674,0,0,0-.552,0h0L.4,3.883a.674.674,0,0,0,0,1.23l1.4.627L.4,6.368A.674.674,0,0,0,.4,7.6l1.438.645L.4,8.889a.674.674,0,0,0,0,1.23l8.516,3.823a.674.674,0,0,0,.552,0l8.516-3.823a.674.674,0,0,0,0-1.23l-1.438-.645L17.981,7.6a.675.675,0,0,0,.4-.615ZM6.428,2.356l1.464.657L6.319,3.72,4.876,3.053ZM9.206,3.6l1.592.715-1.592.736L7.614,4.319Zm4.314-.543-1.426.659-1.573-.706,1.447-.65Zm-5.6,2.587L6.1,6.491,4.436,5.745,6.312,4.9Zm1.284.593L11,7.068,9.19,7.879l-1.79-.8Zm3.092.243L10.49,5.648,12.1,4.9l1.859.835Zm2.976-1.336L13.4,4.3l1.426-.659,1.9.853ZM9.19,1.117l1.464.657-1.447.65L7.743,1.766ZM3.573,3.638,5.016,4.3l-1.895.851L1.657,4.5ZM16.722,9.5,9.19,12.885,1.657,9.5l1.493-.67,5.763,2.587a.675.675,0,0,0,.552,0l5.763-2.587Zm-7.532.86L1.657,6.983,3.11,6.331l5.8,2.6a.673.673,0,0,0,.552,0l5.8-2.6,1.453.652Zm0,0"
                                                          transform="translate(938.81 6564.999)" fill="#f1eee9"/>
                                                </g>
                                            </svg>

                                            <p>Land Size</p>
                                            <p>{marker.katha}</p>
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 width="20" height="20" viewBox="0 0 20 20">
                                                <clipPath id="clip-path">
                                                    <rect id="Rectangle_5800" data-name="Rectangle 5800" width="20"
                                                          height="20"
                                                          transform="translate(938 6562)" fill="#f1eee9"
                                                          stroke="#707070"
                                                          stroke-width="1"/>
                                                </clipPath>
                                                <g id="Mask_Group_19885" data-name="Mask Group 19885"
                                                   transform="translate(-938 -6562)" clip-path="url(#clip-path)">
                                                    <g id="Group_22546" data-name="Group 22546"
                                                       transform="translate(938.903 6506)">
                                                        <g id="Group_22547" data-name="Group 22547"
                                                           transform="translate(0 59)">
                                                            <g id="Group_22546-2" data-name="Group 22546"
                                                               transform="translate(0 0)">
                                                                <path id="Path_8893" data-name="Path 8893"
                                                                      d="M306.291,282.919h-2.7a.711.711,0,0,0,0,1.421h.985l-3.409,3.409a.711.711,0,0,0,1.005,1.005l3.409-3.409v.985a.711.711,0,0,0,1.421,0v-2.7h0A.712.712,0,0,0,306.291,282.919Z"
                                                                      transform="translate(-290.265 -274.962)"
                                                                      fill="#f1eee9"/>
                                                                <path id="Path_8894" data-name="Path 8894"
                                                                      d="M17.482,59H13.289a.711.711,0,0,0-.711.711v3.482H9.1a.711.711,0,0,0-.711.711v3.482H4.9a.711.711,0,0,0-.711.711v3.482H.711A.711.711,0,1,0,.711,73H4.9a.711.711,0,0,0,.711-.711V68.807H9.1a.711.711,0,0,0,.711-.711V64.614h3.482A.711.711,0,0,0,14,63.9V60.421h3.482a.711.711,0,0,0,0-1.421Z"
                                                                      transform="translate(0 -59)" fill="#f1eee9"/>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                            <p>Storied</p>
                                            <p>{marker.storied}</p>
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                 viewBox="0 0 20 20">
                                                <g id="Rectangle_5800" data-name="Rectangle 5800" fill="#f1eee9"
                                                   stroke="#707070" stroke-width="1">
                                                    <rect width="20" height="20" stroke="none"/>
                                                    <rect x="0.5" y="0.5" width="19" height="19" fill="none"/>
                                                </g>
                                            </svg>
                                            <p>Facing</p>
                                            <p>{marker.facing}</p>
                                        </li>
                                    </ul>
                                    <div className="gallery">
                                        <ul>
                                            {
                                                marker.image?.length > 0 &&
                                                marker.image?.filter(f => f.icon === 'on')?.map((e, i) => {
                                                    return (
                                                        <li key={i}>
                                                            <LightgalleryItem group={'any'} src={e?.full_path}>
                                                                <div className="img-wrapper">
                                                                    <Img src={e?.full_path}/>
                                                                </div>
                                                            </LightgalleryItem>
                                                        </li>
                                                    );
                                                })
                                            }
                                        </ul>
                                    </div>
                                    <Button color={'#F1EEE9'} icon_border={'#F1EEE9'} src={`/project/${marker.slug}`}
                                            margin={'40px 0 0'}
                                            text={'LEARN MORE'}/>

                                </div>
                            </div>

                        ))}
                    </div>

                </LightgalleryProvider>
            }
        </StyledComponent>

    );
};

const StyledComponent = styled.section`
  height: 80vh;
  position: relative;
  overflow: hidden;

  .map-button {
    cursor: pointer;

    svg {
      transform: translateX(0);
      transition: transform 0.6s cubic-bezier(0.76, 0, 0.24, 1);
    }

    &:hover {
      svg {
        transform: translateX(10px);
      }
    }
  }

  .google-map-side-gallery {


    .item-single-gallery {
      background: #C0845E;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 2;
      position: absolute;
      padding: 40px;
      transform: translateX(100%);
      min-width: 468px;
      transition: transform 0.6s cubic-bezier(0.76, 0, 0.24, 1);
      box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 7px 1px;

      .close-modal-map {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
      }

      .content {
        height: calc(80vh - 60px);
        overflow-x: scroll;
        /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
          display: none;
        }


        h4 {
          font-size: 20px;
          font-weight: 500;
          line-height: 24px;
          color: #F1EEE9;
          text-transform: uppercase;
          width: 60%;
          margin-bottom: 40px;
        }

        ul {
          display: flex;
          flex-direction: column;
          margin-bottom: 40px;

          li {
            margin-bottom: 10px;
            margin-bottom: 10px;
            display: inline-flex;

            svg {
              height: 20px;
              width: 20px;
              margin-right: 20px;
            }

            p {
              font-size: 12px;
              line-height: 24px;
              color: #F1EEE9;
              text-transform: uppercase;
              margin: 0;
              width: 30%;

              &:last-child {

              }
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      .gallery {
        ul {
          margin: 0;
          gap: 15px;
          flex-wrap: wrap;
          flex-direction: unset;

          li {
            flex: 0 0 calc(50% - 15px);
            margin: 0;

            .react_lightgallery_item {
              width: 100%;
              cursor: pointer;
            }

            .img-wrapper {
              padding-top: calc(100 / 160 * 100%);
              position: relative;
              min-height: 100px;
            }
          }
        }
      }

      &.active {
        transform: translateX(0);
      }


    }
  }


  .poi-info-window div, .poi-info-window a {
    background: transparent;
    color: #F1EEE9;

  }
`;
export default React.memo(GoogleMapsMarkers)