import React from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import TeamBox from "./TeamBox";
import Title from "./Title";
import NavigationIcon from "./NavigationIcon";
import 'swiper/css';
import "swiper/css/pagination";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Navigation} from "swiper";


const Peoples = (data) => {
    return (
        <StyledComponent className={'our-team pt-180 '}>
            <Container>
                <Row>
                    <Col md={12}>
                        <Title text={data?.data?.section_data?.title}/>
                        <NavigationIcon next_id={'people-next'} prev_id={'people-prev'}/>

                    </Col>

                    {
                        data?.data?.posts?.list?.length > 0 &&
                        <Swiper

                            modules={[Autoplay, Navigation]}
                            navigation={{
                                prevEl: '#people-prev',
                                nextEl: '#people-next',
                            }}
                            speed={900}
                        >
                            {
                                data?.data?.posts?.list?.length > 0 &&
                                data?.data?.posts?.list?.map((e, index) => {
                                    return (
                                        <SwiperSlide key={0}>
                                            <Col>
                                                <TeamBox data={e} title={e?.data?.subtitle} des={e?.data?.short_desc}
                                                         img={e?.images?.[0]?.full_path}/>
                                            </Col>
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                    }

                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background: #f1eee9;
  position: relative;
  padding-bottom: 120px;

  h2 {
    margin-bottom: 46px;
  }

  .navigation_button {
    margin-bottom: 40px;
  }

  .swiper-initialized {
    padding-right: 90px;
  }

`;

export default React.memo(Peoples);
