import React ,{useRef , useEffect }from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
import {ImageParallax} from "./ImageParallax";
import gsap from 'gsap'

const InnerBanner = ({img, subtext, title, uptitle,project,notFull}) => {

    const containerRef = useRef(null);



    return (
        <StyledInnerBanner notFull={notFull} project={project} className='InnerBanner '>
            <ImageParallax banner={true} src={img ? img : '/images/static/blurbg.svg'}/>
            <Container>
                <Row>
                    <Col ref={containerRef} md={12}>
                        <p className={'split-text up-title'}>{uptitle ? uptitle : reactHtmlParser(uptitle)}</p>
                        <h1 className={'split-text'}>{title ? reactHtmlParser(title) : ''}</h1>
                        <p className={'split-text'}>{subtext ? reactHtmlParser(subtext) : ''}</p>
                    </Col>
                </Row>
            </Container>
        </StyledInnerBanner>
    );
};

const StyledInnerBanner = styled.section`
  padding-top: ${props => props.notFull ? '75vh' : '100vh'} ;
  position: relative;
  background-color: #2F374B;
  height:${props => props.notFull ? '75vh' : '100vh'};
  overflow: hidden;

  &:after {
    //content: '';
    position: absolute;
    background: linear-gradient(142deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.5018601190476191) 0%);
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }

  .container {
    position: absolute;
    height: 100%;
    margin: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 2;

    h1 {
      margin-bottom: ${props => props.project ? '30px' : '15px'} ;
    }

    h1, p {
      color: #F1EEE9;
      text-transform: uppercase;
    }

    .up-title {
      margin-bottom: 35px;
    }

    .row {
      height: 100%;
      align-items: center;
      justify-content: center;
    }
  }


  @media (max-width: 600px) {
    padding-top: 75vh;
    h1{
      font-size: 2rem;
      line-height: 3rem;
      letter-spacing: 1.5px;
    }
  }




`;

export default React.memo(InnerBanner);
