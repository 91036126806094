import React from 'react';
import styled from 'styled-components';
import {body_font, hover, medium, secondary_wood, text} from "../styles/globalStyleVars";
import ReactHtmlParser from "react-html-parser";
import {Img} from "./Img";
import {Link} from "react-router-dom";
import reactHtmlParser from "react-html-parser";

const ProjectBox = ({img, title, link, katha, type, location}) => {


    return (

        <StyledProjectBox>
            <div className="wrapper">
                <Link to={link ? link : '/'}>
                    <div className={'img-wrapper '}>
                        <Img src={img ? img : '/images/static/blurbg.svg'}/>
                        <div className="content-hover">
                            <div className="contents">
                                {
                                    title &&
                                    <h3>{title ? title : 'Sayed Nagor Vatara, Dhaka'}</h3>
                                }
                                {
                                    katha &&
                                    <h4>{katha ? katha : '7.0 KATHA'}</h4>
                                }

                                <div className="icon">
                                    <ul>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20.2" height="21" viewBox="0 0 20.2 21">
                                                <g id="pool" transform="translate(0.5 0.5)">
                                                    <g id="pool-2" data-name="pool" transform="translate(0 0)">
                                                        <path id="Path_1953" data-name="Path 1953" d="M-88-139.2c0-2.771,4.8,2.771,4.8,0s4.8,2.771,4.8,0,4.8,2.771,4.8,0,4.8,2.771,4.8,0" transform="translate(88 158.4)" fill="none" stroke="#c0845e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                                        <path id="Path_1954" data-name="Path 1954" d="M-81.2-170v-13.6a2.4,2.4,0,0,0-2.4-2.4h0a2.4,2.4,0,0,0-2.4,2.4v.8" transform="translate(86.8 186)" fill="none" stroke="#c0845e" stroke-miterlimit="10" stroke-width="1"/>
                                                        <path id="Path_1955" data-name="Path 1955" d="M-55.2-170v-13.6a2.4,2.4,0,0,0-2.4-2.4h0a2.4,2.4,0,0,0-2.4,2.4v.8" transform="translate(71.2 186)" fill="none" stroke="#c0845e" stroke-miterlimit="10" stroke-width="1"/>
                                                        <line id="Line_89" data-name="Line 89" x2="9.958" transform="translate(5.809 7.519)" fill="none" stroke="#c0845e" stroke-miterlimit="10" stroke-width="1"/>
                                                        <line id="Line_90" data-name="Line 90" x2="9.958" transform="translate(5.809 12.498)" fill="none" stroke="#c0845e" stroke-miterlimit="10" stroke-width="1"/>
                                                    </g>
                                                </g>
                                            </svg>

                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25.203" height="21" viewBox="0 0 25.203 21">
                                                <g id="Group_4745" data-name="Group 4745" transform="translate(0.5 0.5)">
                                                    <rect id="Rectangle_1607" data-name="Rectangle 1607" width="24.203" height="3.458" rx="1.729" transform="translate(0 4.793)" fill="none" stroke="#c0845e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                                    <path id="Rectangle_1608" data-name="Rectangle 1608" d="M0,0H22.474a0,0,0,0,1,0,0V0a9.508,9.508,0,0,1-9.508,9.508H9.508A9.508,9.508,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(0.864 8.251)" fill="none" stroke="#c0845e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                                    <path id="Path_1950" data-name="Path 1950" d="M8,13V9.667A1.667,1.667,0,0,1,9.667,8h0a1.667,1.667,0,0,1,1.667,1.667h0" transform="translate(-6.033 -8)" fill="none" stroke="#c0845e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                                    <path id="Path_1951" data-name="Path 1951" d="M46,50h0a2.5,2.5,0,0,1,2.5,2.5h0" transform="translate(-28.2 -32.5)" fill="none" stroke="#c0845e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                                    <path id="Path_1952" data-name="Path 1952" d="M14.5,50h0A2.5,2.5,0,0,0,12,52.5h0" transform="translate(-8.366 -32.5)" fill="none" stroke="#c0845e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                                </g>
                                            </svg>

                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20.048" height="21" viewBox="0 0 20.048 21">
                                                <g id="Group_4744" data-name="Group 4744" transform="translate(0.5 0.5)">
                                                    <rect id="Rectangle_1603" data-name="Rectangle 1603" width="19.048" height="17.143" fill="none" stroke="#c0845e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                                    <rect id="Rectangle_1604" data-name="Rectangle 1604" width="13.333" height="12.381" transform="translate(2.857 2.857)" fill="none" stroke="#c0845e" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
                                                    <circle id="Ellipse_182" data-name="Ellipse 182" cx="1.429" cy="1.429" r="1.429" transform="translate(10.476 6.667)" fill="none" stroke="#c0845e" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
                                                    <rect id="Rectangle_1605" data-name="Rectangle 1605" width="3.81" height="2.857" transform="translate(13.333 17.143)" fill="none" stroke="#c0845e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                                    <rect id="Rectangle_1606" data-name="Rectangle 1606" width="3.81" height="2.857" transform="translate(1.905 17.143)" fill="none" stroke="#c0845e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                                </g>
                                            </svg>

                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="21.08" height="21" viewBox="0 0 21.08 21">
                                                <g id="Group_4748" data-name="Group 4748" transform="translate(0.437 0.5)">
                                                    <rect id="Rectangle_1610" data-name="Rectangle 1610" width="20.08" height="17.342" transform="translate(0.063 2.658)" fill="none" stroke="#c0845e" stroke-linejoin="round" stroke-width="1"/>
                                                    <line id="Line_96" data-name="Line 96" x2="20.08" transform="translate(0.063 7.222)" fill="none" stroke="#c0845e" stroke-linejoin="round" stroke-width="1"/>
                                                    <path id="Path_1958" data-name="Path 1958" d="M17.352,8.294H12L12.765,6h3.823Z" transform="translate(-9.622 -6)" fill="none" stroke="#c0845e" stroke-linejoin="round" stroke-width="1"/>
                                                    <path id="Path_1959" data-name="Path 1959" d="M43.352,8.294H38L38.765,6h3.823Z" transform="translate(-25.683 -6)" fill="none" stroke="#c0845e" stroke-linejoin="round" stroke-width="1"/>
                                                    <rect id="Rectangle_1611" data-name="Rectangle 1611" width="14.604" height="9.127" transform="translate(2.801 7.222)" fill="none" stroke="#c0845e" stroke-miterlimit="10" stroke-width="1"/>
                                                    <line id="Line_97" data-name="Line 97" x2="7.302" transform="translate(6.452 9.96)" fill="none" stroke="#c0845e" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
                                                    <line id="Line_98" data-name="Line 98" transform="translate(3.583 4.771)" fill="none" stroke="#c0845e" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
                                                    <line id="Line_99" data-name="Line 99" transform="translate(8.354 4.771)" fill="none" stroke="#c0845e" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
                                                    <line id="Line_100" data-name="Line 100" transform="translate(17.1 4.771)" fill="none" stroke="#c0845e" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
                                                    <line id="Line_101" data-name="Line 101" transform="translate(12.33 4.771)" fill="none" stroke="#c0845e" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
                                                </g>
                                            </svg>


                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="information">
                        <ul>
                            {
                                type &&
                                <li>{type ? type : 'RESIDENTIAL'}</li>

                            }
                            {
                                location &&
                                <li>{location ? location : 'SAYED NAGOR VATARA'}</li>
                            }
                        </ul>
                        {
                            title &&
                            <h3>{title ? title : 'Sayed Nagor Vatara, Dhaka'}</h3>
                        }
                    </div>
                </Link>
            </div>
        </StyledProjectBox>

    )
};


const StyledProjectBox = styled.div`
  .wrapper {
    position: relative;

    .img-wrapper {
      padding-top: calc(510 / 370 * 100%);
      position: relative;
      overflow: hidden;

      img {
        //transform: scale(1.01);
        transition: all 0.3s ease-in;
      }

      .content-hover {
        position: absolute;
        inset: 0px;
        display: flex;
        align-items: flex-end;
        padding-bottom: 30px;

        &:after {
          position: absolute;
          inset: 0;
          background: rgba(0, 0, 0, 0.2);
          content: '';
          z-index: 0;
          transition: all 0.5s ease-in;
          opacity: 0;
        }

        .contents {
          background: #F1EEE9;
          padding: 20px;
          width: calc(100% - 60px);
          margin: 0 auto;
          position: relative;
          z-index: 3;
          transition: all 0.3s ease-in;
          transform: translateY(140%);
          h3 {
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 1px;
            color: ${secondary_wood};
            font-family: ${body_font};
            margin-bottom: 20px;
          }

          h4 {
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
            color: ${text};
            font-family: ${body_font};
            margin-bottom: 15px;
          }

          ul {
            border-top: 1px solid #C0845E;
            display: flex;
            padding-top: 15px;
            flex-wrap: wrap;
            gap: 20px;
          }
        }
      }
    }
    .information {
      margin-top: 30px;
      ul {
        display: flex;

        li {
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 1px;
          color: ${secondary_wood};
          position: relative;
          padding-left: 20px;
          text-transform: uppercase;

          &:after {
            content: '';
            height: 5px;
            width: 5px;
            background: ${secondary_wood};
            border-radius: 50%;
            position: absolute;

            right: -12px;
            top: 7px;

          }

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            &:after {
              display: none;
            }
          }
        }
      }

      h3 {
        font-family: ${body_font};
        font-size: 20px;
        text-transform: uppercase;
        line-height: 24px;
        font-weight: 500;
        color: ${text};
        letter-spacing: 0;
      }
    }
    a {
      &:hover {
        .content-hover{
          &:after{
            opacity: 1;
          }
          .contents{
            transform: translateY(0);
          }
        }
        .img-wrapper {
          img {
            //transform: scale(1.05);
          }
        }
        .information{
          h3{
            color: ${secondary_wood}
          }
        }
      }
    }

    

  }
`;

export default React.memo(ProjectBox);














