import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Modal from "react-bootstrap/Modal";
import {Col, Container, Row} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
import CloseModal from "./svg/CloseModal";

const MDPopup = ({
                         show,
                         handleClose,
                         no_img,
                         item,
                         title,
                         description,
                         data,
                         subtitle,
                         img
                     }) => {


    const popupData = data;
    const [winWidth, setWinWidth] = useState(true)
    useEffect(() => {
        if (window.innerWidth > 767) {
            setWinWidth(true)
        } else {
            setWinWidth(false)
        }
        window.addEventListener("resize", () => {
            if (window.innerWidth > 767) {
                setWinWidth(true)
            } else {
                setWinWidth(false)
            }
        });
    }, [])




    return (

        <StyledModal>
            <Modal
                show={show}
                item={item}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="gph_modal popup-version-one"
            >
                <div className="noise"></div>

                <div className="main_scroll" style={{height: '100vh'}}>
                    <Modal.Body>

                        <Container>
                            <Row className={'for-close'}>
                                <div onClick={handleClose} className="modal-close ">
                                    <CloseModal/>
                                </div>

                            </Row>
                        </Container>
                        <Container>
                            <Row>
                                <div className="modal-data d-flex">
                                    <Col sm={12} className="modal-main-content p-0">
                                        <div className="profile-info">
                                            {
                                                popupData?.data?.images?.list?.[0]?.full_path &&
                                                <div className="img-wrp">
                                                    <img
                                                        src={popupData?.data?.images?.list?.[0]?.full_path ? popupData?.data?.images?.list?.[0]?.full_path : 'images/static/blurbg.svg'}/>
                                                </div>
                                            }

                                            {
                                                popupData?.data?.section_data?.subtitle &&
                                                <h5>{reactHtmlParser(popupData?.data?.section_data?.subtitle)}</h5>
                                            }
                                           <p>Managing Director</p>

                                        </div>
                                        <div className="profile-details">
                                            {
                                                popupData?.data?.section_data?.description &&
                                                reactHtmlParser(popupData?.data?.section_data?.description)
                                            }

                                        </div>
                                    </Col>


                                </div>
                            </Row>
                        </Container>
                    </Modal.Body>
                </div>
            </Modal>
        </StyledModal>

    )
};


const StyledModal = styled.div`

  .modal-dialog {
    margin: 0;
  }

  h4 {
    letter-spacing: 1.5px;
  }

`;


export default React.memo(MDPopup);