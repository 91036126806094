import React, {useEffect, useState} from "react";
import Select, {components} from "react-select";
import {black, hover} from "../../styles/globalStyleVars";
import {BsChevronDown, BsX} from "react-icons/bs";
import styled from "styled-components";
import {Accordion, Col, Container, Form, Modal, Row} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
// import iDropdown from "../../public/images/static/i-dropdown.svg";
import Button from "../Button";
import {Link} from 'react-router-dom';

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderRadius: 0,
        color: state.isSelected ? '#FFF' : '#221F1F',
        backgroundColor: state.isSelected ? '#ED1B34' : '#FFF',
        margin: 0,
        fontSize: 12,
        cursor: 'pointer',
        paddingLeft: 25
    }),
    menu: (provided, state) => ({
        ...provided,
        color: '#FFF',
        backgroundColor: state.isSelected ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0)',
        margin: 0,
        borderRadius: 5,
        fontSize: 12,

    }),
    menuList: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#000' : '#FFF',
        borderRadius: 0,
        fontWeight: '600',
        color: '#FFF',
        fontSize: 12,

    }),

};


const IndustrialLube = ({queryParam}) => {

    const [show, setShow] = useState(false);
    const [winWidth, setWinWidth] = useState(true)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        let getLists = document.querySelector('.desktop-lists');
        let getLi = getLists.querySelectorAll('li');
        if (typeof (queryParam) === 'undefined') {
            getLi[0]?.classList.add('active')
        } else {
            getLi[0]?.classList.remove('active')
        }
    }, [queryParam])


    // const optionsOne = theData?.map(location => (
    //     {value: location?.slug, label: location?.title}
    // ));
    //
    const optionsOne = [
        {value: 'Aviation-Products', label: 'Aviation Products'},
        {value: 'circulating-oils', label: 'Circulating Oils'},
        {value: 'compressor-oils', label: 'Compressor Oils'}
    ]

    let handleMobileSelect = (e) => {
        // Router.push({
        //     pathname: '/industrial-specialty-lubricants',
        //     query: {item: e},
        // })
    }

    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    {/*<FontAwesomeIcon icon={props.selectProps.menuIsOpen ? "caret-up" : "caret-down"}/>*/}
                    {/*<img src={props.selectProps.menuIsOpen ? caretUpImg : caretDownImg } alt=""/>*/}
                    <img src={'/'}/>
                </components.DropdownIndicator>
            )
        );
    };


    return (
        <StyledFaq className="job-lists pt-100 pb-160">

            <Container>
                <div className="top-title">
                    <Col sm={{span:8, offset:0}}>
                        <h4>Let's chat about your career aspirations over a cup of coffee.</h4>
                    </Col>
                </div>

                <Row>
                    <Col sm={4} className='job-lists__sidebar'>
                        <div className="job-lists__sidebar__mobile-dropdown">
                            <Select onChange={op => handleMobileSelect(op.value)} styles={customStyles}
                                    components={{DropdownIndicator}}
                                    className='select-here files'
                                    placeholder={'Select'}
                                    options={optionsOne}/>
                        </div>


                        <ul className='desktop-lists'>

                            <li className={'active'}><Link href={'/'}><a>Corporate</a></Link></li>
                            <li className={''}><Link href={'/'}><a>Managerial</a></Link></li>
                            <li className={''}><Link href={'/'}><a>Staff</a></Link></li>
                            <li className={''}><Link href={'/'}><a>General Support</a></Link></li>
                        </ul>
                    </Col>

                    <Col sm={8} className='job-lists__content'>
                        <Accordion defaultActiveKey="1">

                            <Accordion.Item eventKey={`1`}>
                                <Accordion.Header>
                                    {reactHtmlParser('Head of Logistic Service')}
                                    <span><BsChevronDown/></span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p>{reactHtmlParser('We’re constantly seeking experts in seeing the bigger picture. Our Logistics group coordinates and manages logistic service, contracts, and technical evaluations for ground transportation. They also work closely with several MJL Bangladesh Limited career paths to help manage total system costs with a priority on safe, reliable performance.')}</p>

                                    <ul>
                                        <h4>JOB SUMMARY</h4>
                                        <li>Schedule and coordinate completion/Well Work activities</li>
                                        <li>This position is a designated as safety sensitive.</li>
                                    </ul>

                                    <ul>
                                        <h4>JOB RESPONSIBILITIES</h4>
                                        <li>Schedule and coordinate completion/Well Work activities such as frac,
                                            workover, coil tubing, e-line.
                                        </li>
                                        <li>Ensure all work is executed onsite in a safe, efficient, and environmentally
                                            sound manner.
                                        </li>
                                    </ul>

                                    <div className="button-group d-flex" onClick={handleShow}>
                                        <Button text={'Apply'}/>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey={`2`}>
                                <Accordion.Header>
                                    {reactHtmlParser('Maintenance Supervisor (Instrumentation & Electrical) - Huizhou ')}
                                    <span><BsChevronDown/></span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p>{reactHtmlParser('Mobil Jet Oil II is a high performance aircraft-type gas turbine lubricant formulated with a combination of a highly stable synthetic base fluid and a unique chemical additive package.')}</p>
                                    <div className="button-group d-flex" onClick={handleShow}>
                                        <Button text={'Apply'}/>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>

                        </Accordion>
                    </Col>

                </Row>


            </Container>
            <Modal show={show} className='career-modal' onHide={handleClose}>
                <Modal.Body>
                    <div onClick={handleClose} className="modal-close">
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                            <g id="Group_1979" data-name="Group 1979" transform="translate(-1242 -47)">
                                <circle id="Ellipse_86" data-name="Ellipse 86" cx="25" cy="25" r="25" transform="translate(1242 47)" fill="#1c1718"/>
                                <g id="Group_1821" data-name="Group 1821" transform="translate(1260 65)">
                                    <path id="Path_1745" data-name="Path 1745" d="M1097.785,2831.737l13.742,13.742" transform="translate(-1097.785 -2831.737)" fill="none" stroke="#f0ede3" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                    <path id="Path_1746" data-name="Path 1746" d="M0,0,13.742,13.742" transform="translate(0 13.742) rotate(-90)" fill="none" stroke="#f0ede3" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                </g>
                            </g>
                        </svg>
                    </div>

                    <Container>
                        <Row>
                            <Col md={6}>
                                <div className="modal-data d-flex">

                                    <Col className='modal-data__content'>
                                        {/*<SubTitle text={'Corporate'} margin={'0 0 60px'} fontWeight={'400'}/>*/}
                                        <h4>Corporte</h4>
                                        <h3>Head of Logistic Service</h3>

                                        <Form>
                                            <Form.Control className="form-control" type="text" placeholder="Name"/>
                                            <div className="form-divide d-flex">
                                                <Form.Control type="number" placeholder="Phone*"/>
                                                <Form.Control type="email" placeholder="Email*"/>
                                            </div>

                                            <Form.Control as="textarea" placeholder="Message*"/>

                                            <div className="form-divider d-flex">
                                                <div className="form-right__box">
                                                    <Form.Control type="file" placeholder="Upload Photo"/>
                                                </div>
                                                <div className="form-left__box">
                                                    <Form.Control type="file" placeholder="Upload CV"/>
                                                </div>
                                            </div>
                                            <div className="button-group">
                                                <Button text={'Submit'} margin={'60px 0 0'}/>
                                            </div>
                                        </Form>

                                    </Col>


                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

        </StyledFaq>
    );
};

const StyledFaq = styled.section`
  min-height: 70vh;

  .top-title{
    display: flex;
    .col-sm-8{
      padding: 0; 
    }
    margin-bottom: 60px;
    h4{
      font-size: 36px;
      line-height: 40px;
      font-weight: 600;
    }
  }
  
  .job-lists__sidebar {

    &__mobile-dropdown {
      @media (min-width: 551px) {
        display: none;
      }

      img {
        margin: auto;
      }
    }

    p {
      font-size: 32px;
      line-height: 36px;
      font-weight: 600;
      width: 80%;
    }

    ul {
      @media (max-width: 550px) {
        display: none;
      }

      li {
        margin-bottom: 10px;

        a {
          font-size: 24px;
          font-weight: 600;
          line-height: 28px;
          position: relative;

          &:after {
            content: '';
            position: absolute;
            border-radius: 50%;
            top: 0;
            bottom: 0;
            right: -15px;
            margin: auto;
            height: 10px;
            width: 10px;
            background-color: ${hover};
            display: none;
          }
        }

        &.active {
          a {
            color: ${hover};

            &:after {
              display: block;
            }
          }
        }
      }
    }
  }

  .accordion-item {
    margin-bottom: 40px;

    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  .accordion-header {
    position: relative;
    margin: 0;

    button {
      border: none;
      background-color: transparent;
      padding-left: 0;
      color: ${hover};
      font-size: 24px;
      font-weight: bold;
      line-height: 28px;
      text-transform: capitalize;
      padding-bottom: 15px;
      border-bottom: 1px solid ${hover};
      width: 100%;
      text-align: left;
      //margin-bottom: 50px;
      transition: all .4s ease;

      &.collapsed {
        color: #1C1718;
        border-color: #1C1718;

        span {
          background-color: #1C1718;

          svg {
            transform: rotate(0deg);
          }

          &:after {
            background-color: ${hover} !important;
          }
        }
      }
    }

    span {
      position: absolute;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      color: #ffffff;
      background-color: ${hover};
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 16px;

      &:after {
        height: 0;
        width: 0;
        background-color: ${hover};
        border-radius: 50%;
        opacity: 0;
        transition: all .4s ease;
        content: '';
        position: absolute;
      }

      svg {
        font-size: 13px;
        z-index: 2;
        transform: rotate(180deg);
      }

    }

    &:hover {
      span {
        &:after {
          height: 100%;
          width: 100%;
          opacity: 1;
        }
      }

      button {
        color: ${hover};
        border-color: ${hover};
      }
    }
  }

  .accordion-body {
    margin-bottom: 80px;
    p {
      font-size: 16px;
      line-height: 22px;
      font-weight: 600;
      padding-top: 40px;
      color: rgba(28, 23, 24, 0.8);
    ;
    }

    ul {
      margin-top: 40px;

      h4 {
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 24px;
      }

      li {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 10px;
        position: relative;
        padding-left: 20px;

        &:after {
          position: absolute;
          content: '';
          height: 8px;
          width: 8px;
          border-radius: 50%;
          background-color: ${hover};
          left: 0px;
          top: 8px;
        }

        &:nth-last-child(1) {
          margin-bottom: 0;
        }
      }
    }
  }
  
  
  
  .button-group {
    margin-top: 45px;
  }

 
  
  
 


   


  .css-yk16xz-control, .css-1pahdxg-control {
    border-radius: 18px !important;
    border-color: #989898;
    height: 40px;
    background-color: ${hover};
    cursor: pointer;
    padding-left: 15px;
  }

  .css-yk16xz-control .css-1wa3eu0-placeholder, .css-1pahdxg-control .css-1wa3eu0-placeholder, .css-1uccc91-singleValue {
    color: #ffffff !important;
    font-size: 12px !important;
    font-weight: bold;
    cursor: pointer;
  }

  .css-yk16xz-control .css-tlfecz-indicatorContainer, .css-1pahdxg-control .css-tlfecz-indicatorContainer, .css-yk16xz-control .css-1gtu0rj-indicatorContainer, .css-1pahdxg-control .css-1gtu0rj-indicatorContainer {
    margin-right: 20px;
    margin-top: 2px;
  }


  @media (max-width: 767px) {
    overflow: visible;
    .container {
      overflow: visible;
    }

    .job-lists__sidebar {
      margin-bottom: 50px;

      p {
        max-width: 100%;
      }
    }
  }

`;
export default IndustrialLube;

